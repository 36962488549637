<section class="integration-section ptb-120 animate-bg mob-pt-120 ">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-3">
                <div class="integration-list-wrap">
                    <a href="integration-single.html" class="integration-1 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                        <img src="assets/img/products/1.png" alt="integration" class="img-fluid rounded-circle">
                    </a>
                    <a href="integration-single.html" class="integration-2 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                        <img src="assets/img/products/2.png" alt="integration" class="img-fluid rounded-circle">
                    </a>
                    <a href="integration-single.html" class="integration-3 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                        <img src="assets/img/products/3.png" alt="integration" class="img-fluid rounded-circle">
                    </a>

                    <a href="integration-single.html" class="integration-4 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                        <img src="assets/img/products/4.png" alt="integration" class="img-fluid rounded-circle">
                    </a>
                    <a href="integration-single.html" class="integration-5 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                        <img src="assets/img/products/5.png" alt="integration" class="img-fluid rounded-circle">
                    </a>
                    <a href="integration-single.html" class="integration-6 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                        <img src="assets/img/products/6.png" alt="integration" class="img-fluid rounded-circle">
                    </a>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="section-heading text-center my-5 my-lg-0 my-xl-0">                    
                    <h2>ASZ Products</h2>
                    <h4 class="text-primary h5">Bestow high-end quality, usability <br/> and accessibility with our custom-made<br/>products to valuable clients.</h4>
                   
                </div>
            </div>
            <div class="col-lg-3">
                <div class="col-lg-4">
                    <div class="integration-list-wrap">
                        <a href="integration-single.html" class="integration-7 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                            <img src="assets/img/products/7.png" alt="integration" class="img-fluid rounded-circle">
                        </a>
                        <a href="integration-single.html" class="integration-8 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                            <img src="assets/img/products/8.png" alt="integration" class="img-fluid rounded-circle">
                        </a>
                        <a href="integration-single.html" class="integration-9 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                            <img src="assets/img/products/9.png" alt="integration" class="img-fluid rounded-circle">
                        </a>

                        <a href="integration-single.html" class="integration-10 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                            <img src="assets/img/products/10.png" alt="integration" class="img-fluid rounded-circle">
                        </a>
                        <a href="integration-single.html" class="integration-11 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                            <img src="assets/img/products/11.png" alt="integration" class="img-fluid rounded-circle">
                        </a>
                        <a href="integration-single.html" class="integration-12 bg-white" data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                            <img src="assets/img/products/12.png" alt="integration" class="img-fluid rounded-circle">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="feature-section pt-120 pb-120">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                <div class="mb-4" data-aos="fade-up">
                    <h2>Product details hadding</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                </div>
            </div>
            <div class="col-lg-6 order-lg-1">
                <div class="pr-lg-4 position-relative" data-aos="fade-up">
                    <!--animated shape start-->
                    
                    <!--animated shape end-->
                    <div class="bg-light text-center rounded-custom overflow-hidden py-3 px-3 mx-lg-auto">
                       
                        <div class="position-relative ">
                            <img class="position-relative z-2 w-100 h-auto" src="assets/img/des1.png" alt="Image Description">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-section pt-60 pb-120">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 mb-7 mb-lg-0">
                <div class="mb-4" data-aos="fade-up">
                    <h2>Product details  hadding</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="pr-lg-4 position-relative" data-aos="fade-up">
                    
                    <div class="bg-light text-center shadow-sm rounded-custom overflow-hidden  py-3 px-3 mx-lg-auto">
                       
                        <div class="position-relative">
                            <img class="position-relative z-2 w-100 h-auto" src="assets/img/des2.png" alt="Image Description">
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="masonary-blog-section ptb-120 bg-light">
    <div class="container">       
        <div class="row">
            <div class="col-lg-4  col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w1.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">                        
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                       
                    <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                        <a href="">
                            <div class="d-flex align-items-center">
                                <div class="avatar-info">
                                    <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                </div>
                            </div>
                        </a>
                        <div class="article-category">
                            <a href="" class="d-inline-block text-dark badge bg-danger-soft">Angular</a>
                        </div>
                    </div>    

                    </div>
                </div>
            </div>
            
            
            <div class="col-lg-4  col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w2.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-primary-soft">Bootstrap</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>
            <div class="col-lg-4  col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w3.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-success-soft">React</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>
            <div class="col-lg-4  col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w1.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">                        
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                       
                    <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                        <a href="">
                            <div class="d-flex align-items-center">
                                <div class="avatar-info">
                                    <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                </div>
                            </div>
                        </a>
                        <div class="article-category">
                            <a href="" class="d-inline-block text-dark badge bg-danger-soft">Angular</a>
                        </div>
                    </div>    

                    </div>
                </div>
            </div>
            
            
            <div class="col-lg-4 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w2.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-primary-soft">Bootstrap</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w3.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-success-soft">React</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w1.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">                        
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                       
                    <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                        <a href="">
                            <div class="d-flex align-items-center">
                                <div class="avatar-info">
                                    <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                </div>
                            </div>
                        </a>
                        <div class="article-category">
                            <a href="" class="d-inline-block text-dark badge bg-danger-soft">Angular</a>
                        </div>
                    </div>    

                    </div>
                </div>
            </div>
            
            
            <div class="col-lg-4  col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w2.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-primary-soft">Bootstrap</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>
                        
        </div>
    </div>
</section>