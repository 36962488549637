<div class="cross">
  <i class="fa-solid fa-circle-xmark"></i>
</div>
<section class="career-apply">
  <div class="career-form-image">
    <img src="assets/img/asz/pexels-career.jpg" />
  </div>
  <div class="career-form">
    <h4>Ready to sky-rocket your Career?</h4>
    <form>
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Name</label>
              <input type="name" class="form-control" placeholder="Name" />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Email</label>
              <input type="email" class="form-control" placeholder="Email" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Years of Exprience</label>
              <input
                type="name"
                class="form-control"
                placeholder="Years of Exprience"
              />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Apply for</label>
              <i class="fa-solid fa-angle-down downs"></i>
              <select class="form-control selected">
                <option>Apply for</option>
                <option>Developer</option>
                <option>Designer</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Phone Number</label>
              <input
                type="name"
                class="form-control"
                placeholder="Phone Number"
              />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label>Upload Resume</label>
              <div class="field_2">
                <div class="resume">
                  <div class="upload-btn">
                    <i class="fa fa-paperclip"></i>
                    <span class="hidden-xs"> Upload Resume </span>
                    <input type="file" name="resume" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <textarea
                class="form-control"
                placeholder="We are looking for experienced, professionals, with a niche skills in their expertise."
              ></textarea>
            </div>
          </div>
        </div>

        <div class="apply-now-btn">
          <button class="go">Reset</button>
          <button class="appnow">Apply Now</button>
        </div>
      </div>
    </form>
  </div>
</section>
