<section class="about-header-section pt-120 mob-pt-120 position-relative overflow-hidden bg-light" style="background: url('assets/img/food.jpg')no-repeat center left">
    <div class="">
        <div class="row">
            <div class="col-lg-3" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200">
                <div class="integration-list-wrap">
                    <img src="assets/img/food3.png" class="img-size"/>                   
                </div>
            </div>
            <div class="col-lg-6 col-12  d-flex justify-content-center align-items-center" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200">
                <div class="section-heading text-center my-5 my-lg-0 my-xl-0">                    
                    <h3 class="text-white">Fuel Your Food Business With <br/> A Complete Technology Solution</h3> 
                    <button href="" class="mt-4 button-secondary">Book Your Demo- It’s Free!</button>  
                    <p  class="text-white mt-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> 
                    
                    
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="bg-white position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z-2 py-5">
    </div> -->
</section>



<section class="image-feature pt-120 pb-120">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-lg-5 col-12">
                <div class="feature-img-content">
                    <div class="section-heading" data-aos="fade-up">
                        <h2>FOOD DELIVERY</h2>
                        <p>WOO COMMERCE COMPATIBLE</p>
                    </div>
                    <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200">
                        <li>
                            <div class="icon-box">
                                <img class="img-fluid" src="assets/img/woo-1-1.jpg">
                            </div>
                            <h3 class="h5">Fast Services</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing.</p>
                        </li>
                        <li>
                            <div class="icon-box">
                                <img class="img-fluid" src="assets/img/woo-1-2.jpg">
                            </div>
                            <h3 class="h5">Free delivery</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="feature-img-holder p-lg-5 pb-3">
                    <div class="bg-danger-soft rounded-custom position-relative d-block feature-img-wrap">
                        <div class="z-1 position-relative">
                            <img src="assets/img/food-img2.JPG" class="img-fluid rounded-custom position-relative" alt="feature-image" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200">
                            <img src="assets/img/food-img1.JPG" class="img-fluid rounded-custom shadow position-absolute top--100 right--100 hide-medium food-imags" alt="feature-image" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200">
                        </div>
                        <div class="position-absolute bg-dark-soft z--1 dot-mask dm-size-12 dm-wh-250 bottom-left"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="promo-section ptb-120 bg-light ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-10" >
                <div class="section-heading text-center" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                    <h3>We’ve Helped Over <span class="text-warning">9 Multi-Million Dollar</span> Food Startups In The
                        Last Six Years And The Numbers Speak Themselves</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                <div class="food-style p-3 text-center d-flex flex-column h-100" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                    <div class="promo-card-info d-flex align-items-center justify-content-flex-start">
                        <h3 class="display-5 fw-bold mb-0 ml-4 mr-4">
                            <img src="assets/img/foot-m1.webp" width="75px"/>
                        </h3>
                        <div class="text-left">
                        <h2> 2.4M </h2>
                        <p class="mb-0">Orders Processed</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                <div class="food-style p-3 text-center d-flex flex-column h-100" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                    <div class="promo-card-info d-flex align-items-center justify-content-flex-start">
                        <h3 class="display-5 fw-bold mb-0 ml-4 mr-4">
                            <img src="assets/img/foot-m2.webp" width="75px"/>
                        </h3>
                        <div class="text-left">
                        <h2> 7K </h2>
                        <p class="mb-0">Customer Reviews</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                <div class="food-style p-3 text-center d-flex flex-column h-100" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                    <div class="promo-card-info d-flex align-items-center justify-content-flex-start">
                        <h3 class="display-5 fw-bold mb-0 ml-4 mr-4">
                            <img src="assets/img/foot-m3.webp" width="75px"/>
                        </h3>
                        <div class="text-left">
                        <h2> 340K </h2>
                        <p class="mb-0">Monthly Transactions</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>



<section class="page-header position-relative overflow-hidden ptb-120 bg-dark" style="background: url('assets/img/banner-food.jpg')no-repeat; background-size:cover" >
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="text-center"  data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                    <h1 class="display-5 fw-bold text-dark">Potato Kurma Onion</h1>
                    <h3 class="lead text-success">Way pushed doing of overall someone fresh brief shall, a size harmonics. Various prosecution disguised audience</h3>
                </div>
            </div>
        </div>
        <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"></div>
    </div>
</section>


<section class="feature-section two-bg-dark-light-cl ptb-120">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-6 col-md-6">
                <div class="image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                    <img src="assets/img/food-img.png" alt="feature img" class="img-fluid shadow rounded-custom">
                </div>
            </div>
            <div class="col-lg-5 col-md-6">
                <div class="feature-content-wrap" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                    <h2 class="color-red">Customized The Ordering Experience</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p>
                    <ul class="list-unstyled mt-5">
                        <li class="d-flex align-items-start mb-4">
                            <div class="icon-box bg-primary rounded me-4">
                                <img _ngcontent-cpe-c116="" src="assets/img/woo-1-3.jpg" class="img-fluid">
                            </div>
                            <div class="icon-content">
                                <h3 class="h5 color-red">Single Restaurants</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry.
                                </p>
                            </div>
                        </li>
                        <li class="d-flex align-items-start mb-4">
                            <div class="icon-box bg-danger rounded me-4">
                                <img _ngcontent-cpe-c116="" src="assets/img/woo-1-4.jpg" class="img-fluid">
                            </div>
                            <div class="icon-content">
                                <h3 class="h5 color-red">Food Chain Stores</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry  Lorem Ipsum has been the industry.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>




