<!--preloader start-->
<!-- <div id="preloader">
  <div class="preloader-wrap">
      <img src="assets/img/favicon.png" alt="logo" class="img-fluid preloader-icon" />
      <div class="loading-bar"></div>
  </div>
</div> -->
<!--preloader end-->

<div class="main-wrapper">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
<app-scroll-top></app-scroll-top>
<!-- <button class="ScrollTop-btn">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</button> -->

<div class="checkbox">
  <button class="chat-button">
    
    <a href="https://wa.me/+919740703030" target="_blank"><img src="../assets/img/asz/WhatsApp.svg.png" class="img-fluid" /></a>
  </button>
</div>
