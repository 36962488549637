<section class="software-banner-area">
  <div class="banner_bg_img">
    <img src="../../../assets/img/asz/round-dot.svg" class="img-fluid" />
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="software-banner-content">
          <h5>We provide</h5>
          <h2>Software Development, Automation and Integration services to
            Businesses Worldwide.</h2>
          <!-- <p>
            Software Development, Automation and Integration services to
            Businesses Worldwide.
          </p> -->
          <div class="LearnMore">
            <a class="our_solutions mr-3 click-btn" (click)="scroll(target)">
              Explore Our Offering! &nbsp;&nbsp;<i
                class="fas fa-arrow-right"
              ></i>
            </a>
            <a routerLink="/contact-us" class="get_touch">
              Get In Touch! &nbsp;&nbsp;<i class="fas fa-arrow-right"></i>
            </a>
          </div>
          <div class="bottom_sec">
            <img
              src="../../../assets/img/asz/scribbled-arrow.svg"
              class="img-fluid"
            />
            <p>
              ASZ Technologies is a top-notch software company providing digital
              transformation and product solutions to businesses and industry
              verticals.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="software_banner_sec">
          <div class="software-banner-image"></div>
          <img src="../../../assets/img/asz/dots_6.png" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="home_box_section">
  <div class="area">
    <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
  <div class="container md-width">
    <div class="row">
      <div class="col-md-12">
        <div class="home-icon-box">
          <div class="ibox">
            <img
              src="../../../assets/img/asz/software-development.png"
              class="img-fluid"
            />
            <p>Software<br />Development</p>
          </div>
          <div class="ibox">
            <img
              src="../../../assets/img/asz/staff-augmentation.png"
              class="img-fluid"
            />
            <p>Staff<br />Augmentation</p>
          </div>
          <div class="ibox">
            <img
              src="../../../assets/img/asz/application-moderization.png"
              class="img-fluid"
            />
            <p>Digital <br />Transformation</p>
          </div>
          <div class="ibox">
            <img
              src="../../../assets/img/asz/mobile-app-development.png"
              class="img-fluid"
            />
            <p>Mobile Apps<br />Development</p>
          </div>
          <div class="ibox">
            <img
              src="../../../assets/img/asz/hybrid-outsourcing.png"
              class="img-fluid"
            />
            <p>
              Hybrid<br />
              OutSourcing
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container Explore-offer-sec" #target>
    <div class="row">
      <div class="col-md-12">
        <div class="explore_offering">
          <h2><span>Explore</span> Our Offering</h2>
        </div>
      </div>
    </div>
    <!-- <div class="explore_offer">
      <div class="client_list">
        <div class="owl-carousel owl-theme">
          <div class="item">
            <div class="bx_wrapper">
              <h2><span>Software</span> Development</h2>
              <p>
                The development of reliable and scalable software solutions for
                any OS, browser and device. We bring together deep industry
                expertise and the latest IT advancements to deliver custom
                solutions and products that perfectly fit the needs and behavior
                of their users.
              </p>
              <ul>
                <li>Software consulting</li>
                <li>Custom software development</li>
                <li>Software development outsourcing</li>
                <li>Software product development</li>
                <li>Team augmentation</li>
                <li>Cloud application development</li>
                <li>Legacy software modernization</li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="bx_wrapper">
              <h2><span>Testing</span> & QA</h2>
              <p>
                We offer full-range QA and testing outsourcing services, can
                help to develop your QA or enhance the existing one, assist you
                in TCoE setup and evolution. We perform end-to-end testing of
                mobile, web and desktop application at each stage of the
                development lifecycle.
              </p>
              <ul>
                <li>QA outsourcing</li>
                <li>QA consulting</li>
                <li>Security testing</li>
                <li>Functional testing</li>
                <li>Usability testing</li>
                <li>Performance testing</li>
                <li>Test automation</li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="bx_wrapper">
              <h2><span>Application</span> Services</h2>
              <p>
                The full set of services around development and maintenance of
                complex business-critical applications. Our experts build, test,
                deploy, protect, manage, migrate and optimize enterprise-scale
                digital solutions ensuring they’re always up and running and
                achieve the optimal TCO.
              </p>
              <ul>
                <li>Application management</li>
                <li>Application modernization</li>
                <li>Application integration</li>
                <li>Application security services</li>
                <li>Application development</li>
                <li>Application testing</li>
                <li>Application maintenance and support</li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="bx_wrapper">
              <h2><span>UX/UI</span> Design</h2>
              <p>
                User experience and user interface design for all types of
                websites, SaaS, and web/mobile apps. We combine the latest UI/UX
                trends with our customers’ individual goals and needs to deliver
                intuitive, vibrant, and impactful designs that power up
                businesses.
              </p>
              <ul>
                <li>User Interface (UI) Design</li>
                <li>Responsive Web App Design</li>
                <li>Software-as-as-Service (SaaS) UI Design</li>
                <li>Ecommerce Website Design</li>
                <li>User Experience (UX) Design</li>
                <li>Website Redesign</li>
                <li>Responsive Web Design</li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="bx_wrapper">
              <h2><span>IT </span> Consulting</h2>
              <p>
                Our experts can help to develop and implement an effective IT
                strategy, assist in smooth digital transformation and system
                integration as well as advise on improvements to your digital
                customer experience.
              </p>
              <ul>
                <li>Digital Transformation Consulting</li>
                <li>Project Management Consulting</li>
                <li>Digital Crisis Management Consulting</li>
                <li>IT Service Management Consulting</li>
                <li>Solution consulting</li>
                <li>Platform consulting</li>
                <li>Enterprise IT consulting</li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="bx_wrapper">
              <h2><span>Data</span> Analytics</h2>
              <p>
                We support businesses in achieving fact-based decision-making by
                converting their historical and real-time, traditional and big
                data into actionable insights. Our services are tailored to make
                the raw data and the environment ready, as well as strengthen
                the business with advanced analytics capabilities.
              </p>
              <ul>
                <li>Business Intelligence</li>
                <li>Big Data</li>
                <li>Data Warehousing</li>
                <li>Data Science</li>
                <li>Data Management</li>
                <li>Machine and Deep Learning</li>
                <li>Data Analytics as a Service</li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="bx_wrapper">
              <h2><span>Cybersecurity</span> Services</h2>
              <p>
                Equipped with 19-year experience in information security and
                employing ISO 27001 certified information security management
                practices, we help to achieve the robust protection of the
                companies’ applications and networks.
              </p>
              <ul>
                <li>Managed security services</li>
                <li>Compliance testing</li>
                <li>Information security consulting</li>
                <li>Security code review</li>
                <li>Application security implementation</li>
                <li>Vulnerability assessment and penetration testing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div> -->
    <div class="row">
      <div class="col-md-12">
        <div class="offer_tabing">
          <div class="row web_explore_offer">
            <div class="col-md-4 pr-0 pl-0">
              <div class="left_sec">
                <ul>
                  <ng-container *ngFor="let item of leftsec">
                    <li>
                      <a
                        (click)="changeItem(item.value)"
                        [ngClass]="{ active: changeValue == item.text }"
                        >{{ item.text }}</a
                      >
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="col-md-8 pl-0 pr-0">
              <div class="right_sec">
                <div
                  class="bx_wrapper"
                  *ngIf="changeValue == 'Software Development'"
                >
                  <h2><span>Software</span> Development</h2>
                  <p>
                    The development of reliable and scalable software solutions
                    for any OS, browser and device. We bring together deep
                    industry expertise and the latest IT advancements to deliver
                    custom solutions and products that perfectly fit the needs
                    and behavior of their users.
                  </p>
                  <ul>
                    <li>Software consulting</li>
                    <li>Custom software development</li>
                    <li>Software development outsourcing</li>
                    <li>Software product development</li>
                    <li>Team augmentation</li>
                    <li>Cloud application development</li>
                    <li>Legacy software modernization</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/software-development">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="bx_wrapper" *ngIf="changeValue == 'Testing & QA'">
                  <h2><span>Testing</span> & QA</h2>
                  <p>
                    We offer full-range QA and testing outsourcing services, can
                    help to develop your QA or enhance the existing one, assist
                    you in TCoE setup and evolution. We perform end-to-end
                    testing of mobile, web and desktop application at each stage
                    of the development lifecycle.
                  </p>
                  <ul>
                    <li>QA outsourcing</li>
                    <li>QA consulting</li>
                    <li>Security testing</li>
                    <li>Functional testing</li>
                    <li>Usability testing</li>
                    <li>Performance testing</li>
                    <li>Test automation</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/testing-&-QA">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div
                  class="bx_wrapper"
                  *ngIf="changeValue == 'Application Services'"
                >
                  <h2><span>Application</span> Services</h2>
                  <p>
                    The full set of services around development and maintenance
                    of complex business-critical applications. Our experts
                    build, test, deploy, protect, manage, migrate and optimize
                    enterprise-scale digital solutions ensuring they’re always
                    up and running and achieve the optimal TCO.
                  </p>
                  <ul>
                    <li>Application management</li>
                    <li>Application modernization</li>
                    <li>Application integration</li>
                    <li>Application security services</li>
                    <li>Application development</li>
                    <li>Application testing</li>
                    <li>Application maintenance and support</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/mobile-development">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="bx_wrapper" *ngIf="changeValue == 'UX/UI Design'">
                  <h2><span>UX/UI</span> Design</h2>
                  <p>
                    User experience and user interface design for all types of
                    websites, SaaS, and web/mobile apps. We combine the latest
                    UI/UX trends with our customers’ individual goals and needs
                    to deliver intuitive, vibrant, and impactful designs that
                    power up businesses.
                  </p>
                  <ul>
                    <li>User Interface (UI) Design</li>
                    <li>Responsive Web App Design</li>
                    <li>Software-as-as-Service (SaaS) UI Design</li>
                    <li>Ecommerce Website Design</li>
                    <li>User Experience (UX) Design</li>
                    <li>Website Redesign</li>
                    <li>Responsive Web Design</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/UX/UI-design">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="bx_wrapper" *ngIf="changeValue == 'IT Consulting'">
                  <h2><span>IT </span> Consulting</h2>
                  <p>
                    Our experts can help to develop and implement an effective
                    IT strategy, assist in smooth digital transformation and
                    system integration as well as advise on improvements to your
                    digital customer experience.
                  </p>
                  <ul>
                    <li>Digital Transformation Consulting</li>
                    <li>Project Management Consulting</li>
                    <li>Digital Crisis Management Consulting</li>
                    <li>IT Service Management Consulting</li>
                    <li>Solution consulting</li>
                    <li>Platform consulting</li>
                    <li>Enterprise IT consulting</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/IT-consulting">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="bx_wrapper" *ngIf="changeValue == 'Data Analytics'">
                  <h2><span>Data</span> Analytics</h2>
                  <p>
                    We support businesses in achieving fact-based
                    decision-making by converting their historical and
                    real-time, traditional and big data into actionable
                    insights. Our services are tailored to make the raw data and
                    the environment ready, as well as strengthen the business
                    with advanced analytics capabilities.
                  </p>
                  <ul>
                    <li>Business Intelligence</li>
                    <li>Big Data</li>
                    <li>Data Warehousing</li>
                    <li>Data Science</li>
                    <li>Data Management</li>
                    <li>Machine and Deep Learning</li>
                    <li>Data Analytics as a Service</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/data-analytics">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div
                  class="bx_wrapper"
                  *ngIf="changeValue == 'Cybersecurity Services'"
                >
                  <h2><span>Cybersecurity</span> Services</h2>
                  <p>
                    Equipped with 19-year experience in information security and
                    employing ISO 27001 certified information security
                    management practices, we help to achieve the robust
                    protection of the companies’ applications and networks.
                  </p>
                  <ul>
                    <li>Managed security services</li>
                    <li>Compliance testing</li>
                    <li>Information security consulting</li>
                    <li>Security code review</li>
                    <li>Application security implementation</li>
                    <li>Vulnerability assessment and penetration testing</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/cybersecurity-services">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mobile_explore_offer">
            <div class="right_sec">
              <carousel
                [cellsToShow]="1"
                [cellsToScroll]="1"
                [arrows]="false"
                [dots]="true"
              >
                <div class="carousel-cell bx_wrapper">
                  <h2><span>Software</span> Development</h2>
                  <p>
                    The development of reliable and scalable software solutions
                    for any OS, browser and device. We bring together deep
                    industry expertise and the latest IT advancements to deliver
                    custom solutions and products that perfectly fit the needs
                    and behavior of their users.
                  </p>
                  <ul>
                    <li>Software consulting</li>
                    <li>Custom software development</li>
                    <li>Software development outsourcing</li>
                    <li>Software product development</li>
                    <li>Team augmentation</li>
                    <li>Cloud application development</li>
                    <li>Legacy software modernization</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/software-development">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="carousel-cell bx_wrapper">
                  <h2><span>Testing</span> & QA</h2>
                  <p>
                    We offer full-range QA and testing outsourcing services, can
                    help to develop your QA or enhance the existing one, assist
                    you in TCoE setup and evolution. We perform end-to-end
                    testing of mobile, web and desktop application at each stage
                    of the development lifecycle.
                  </p>
                  <ul>
                    <li>QA outsourcing</li>
                    <li>QA consulting</li>
                    <li>Security testing</li>
                    <li>Functional testing</li>
                    <li>Usability testing</li>
                    <li>Performance testing</li>
                    <li>Test automation</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/testing-&-QA">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="carousel-cell bx_wrapper">
                  <h2><span>Application</span> Services</h2>
                  <p>
                    The full set of services around development and maintenance
                    of complex business-critical applications. Our experts
                    build, test, deploy, protect, manage, migrate and optimize
                    enterprise-scale digital solutions ensuring they’re always
                    up and running and achieve the optimal TCO.
                  </p>
                  <ul>
                    <li>Application management</li>
                    <li>Application modernization</li>
                    <li>Application integration</li>
                    <li>Application security services</li>
                    <li>Application development</li>
                    <li>Application testing</li>
                    <li>Application maintenance and support</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/mobile-development">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="carousel-cell bx_wrapper">
                  <h2><span>UX/UI</span> Design</h2>
                  <p>
                    User experience and user interface design for all types of
                    websites, SaaS, and web/mobile apps. We combine the latest
                    UI/UX trends with our customers’ individual goals and needs
                    to deliver intuitive, vibrant, and impactful designs that
                    power up businesses.
                  </p>
                  <ul>
                    <li>User Interface (UI) Design</li>
                    <li>Responsive Web App Design</li>
                    <li>Software-as-as-Service (SaaS) UI Design</li>
                    <li>Ecommerce Website Design</li>
                    <li>User Experience (UX) Design</li>
                    <li>Website Redesign</li>
                    <li>Responsive Web Design</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/UX/UI-design">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="carousel-cell bx_wrapper">
                  <h2><span>IT </span> Consulting</h2>
                  <p>
                    Our experts can help to develop and implement an effective
                    IT strategy, assist in smooth digital transformation and
                    system integration as well as advise on improvements to your
                    digital customer experience.
                  </p>
                  <ul>
                    <li>Digital Transformation Consulting</li>
                    <li>Project Management Consulting</li>
                    <li>Digital Crisis Management Consulting</li>
                    <li>IT Service Management Consulting</li>
                    <li>Solution consulting</li>
                    <li>Platform consulting</li>
                    <li>Enterprise IT consulting</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/IT-consulting">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="carousel-cell bx_wrapper">
                  <h2><span>Data</span> Analytics</h2>
                  <p>
                    We support businesses in achieving fact-based
                    decision-making by converting their historical and
                    real-time, traditional and big data into actionable
                    insights. Our services are tailored to make the raw data and
                    the environment ready, as well as strengthen the business
                    with advanced analytics capabilities.
                  </p>
                  <ul>
                    <li>Business Intelligence</li>
                    <li>Big Data</li>
                    <li>Data Warehousing</li>
                    <li>Data Science</li>
                    <li>Data Management</li>
                    <li>Machine and Deep Learning</li>
                    <li>Data Analytics as a Service</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/data-analytics">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="carousel-cell bx_wrapper">
                  <h2><span>Cybersecurity</span> Services</h2>
                  <p>
                    Equipped with 19-year experience in information security and
                    employing ISO 27001 certified information security
                    management practices, we help to achieve the robust
                    protection of the companies’ applications and networks.
                  </p>
                  <ul>
                    <li>Managed security services</li>
                    <li>Compliance testing</li>
                    <li>Information security consulting</li>
                    <li>Security code review</li>
                    <li>Application security implementation</li>
                    <li>Vulnerability assessment and penetration testing</li>
                  </ul>
                  <div class="explore-btn">
                    <a routerLink="/cybersecurity-services">
                      Explore More &nbsp;&nbsp;<i
                        class="fas fa-arrow-right"
                      ></i>
                    </a>
                  </div>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="home_why_choose">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="about_img">
          <img src="../../../assets/img/asz/about_su.webp" class="img-fluid" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="about_content">
          <div class="heading">
            <h2><span>Why</span> Choose Us</h2>
            <p>
              ASZ Technologies is one of Asia’s leading innovative IT Solution Providers offering comprehensive 
              and focused solutions in Cloud, Security, Media and Mobile. 
            </p>
            <p>ASZ technologies brings deep competencies in assisting organisations in their digital transformation 
              journey and business technology alignment.
              </p>
              <p>As a consultancy-based company, ASZ Technologies creates the necessary workflow to empower 
                entities within public and private sectors to leverage on technologies 
                to improve on their existing workflows and infrastructure. 
                </p>
            <a routerLink="/about-us" class="btn_about">
              About Us &nbsp;&nbsp;<i class="fas fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 col-lg-1"></div>
          <div class="col-md-12 col-lg-10">
            <div class="about_port_details">
              <div class="about_bottom">
                <div class="ibox">
                  <div class="why-choose-img">
                    <img
                      src="../../../assets/img/asz/icon1.png"
                      class="img-fluid"
                    />
                  </div>
                  <p>360° Approach</p>
                </div>
                <div class="ibox">
                  <img
                    src="../../../assets/img/asz/icon4.png"
                    class="img-fluid"
                  />
                  <p>Client-Centricity</p>
                </div>
                <div class="ibox">
                  <img
                    src="../../../assets/img/asz/icon2.png"
                    class="img-fluid"
                  />
                  <p>Domain Expertise</p>
                </div>
                <div class="ibox">
                  <img
                    src="../../../assets/img/asz/icon5.png"
                    class="img-fluid"
                  />
                  <p>Time-To-Market</p>
                </div>
                <div class="ibox">
                  <img
                    src="../../../assets/img/asz/icon3.png"
                    class="img-fluid"
                  />
                  <p>A-Class team</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-1"></div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="technologies_we_ofer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="heading">
          <h2><span>Technologies</span> We Offering</h2>
        </div>
      </div>
    </div>
    <div class="tech_tabing">
      <div class="row web_tech_offer">
        <div class="col-md-4 pr-0">
          <div class="left_sec">
            <ul>
              <ng-container *ngFor="let ite of techsec">
                <li>
                  <a
                    (click)="techItem(ite.value)"
                    [ngClass]="{ active: techValue == ite.text }"
                    >{{ ite.text }}</a
                  >
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="col-md-8 pl-0">
          <div class="right_sec">
            <div class="bx_wrapper" *ngIf="techValue == 'Web'">
              <h2><span>Web</span> Technologies</h2>
              <p>
                Make your product stand out with an appealing and interactive
                interface built by the ASZ team of experts! Our proficient
                developers utilize the best front-end and back-end technologies
                to provide you with solutions that cater to your requirements..
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/web_1.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_2.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_3.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_5.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_6.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_7.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_8.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_9.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_10.svg"
                    class="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'Mobile'">
              <h2><span>Mobile</span> Technologies</h2>
              <p>
                Make your product stand out with an appealing and interactive
                interface built by the ASZ team of experts! Our proficient
                developers utilize the best front-end and back-end technologies
                to provide you with solutions that cater to your requirements..
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/mobile_1.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/mobile_2.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/mobile_3.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/mobile_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/android.svg"
                    class="img-fluid"
                    alt="android"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/img_204828.png"
                    class="img-fluid"
                    alt="ios"
                  />
                </li>
              </ul>
            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'Desktop'">
              <h2><span>Desktop</span> Technologies</h2>
              <p>
                The full set of services around development and maintenance of
                complex business-critical applications. Our experts build, test,
                deploy, protect, manage, migrate and optimize enterprise-scale
                digital solutions ensuring they’re always up and running and
                achieve the optimal TCO.
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_1.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_2.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_3.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_5.svg"
                    class="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'Database'">
              <h2><span>Database</span></h2>
              <p>
                Secure your data on your trusted databases. Data organization is
                the practice of categorizing and classifying data to make it
                more usable
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/database_1.webp"
                    class="img-fluid"
                    alt="mango-db"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_2.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_3.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_5.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_6.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_7.svg"
                    class="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'DevOps'">
              <h2><span>DevOps </span></h2>
              <p>
                Bridge the gap between operations and development with our
                outstanding DevOps services. With the latest tools in our tech
                stack, we are capable of meeting any of your requests..
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_1.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_2.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_3.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_5.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_6.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/github.svg"
                    class="img-fluid"
                    alt="github"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/bitbucket.webp"
                    class="img-fluid"
                    alt="bitbuket"
                  />
                </li>
              </ul>
            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'Cloud Services'">
              <h2><span>Cloud</span> Services</h2>
              <p>
                Gain greater flexibility, build cost-effective software, benefit
                from improved agility and enhance performance with our
                high-quality cloud services. We use cutting-edge tools to
                provide you with smart cloud solutions..
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_1.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_2.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_3.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_4.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_5.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_6.webp"
                    class="img-fluid"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row mob_tech_offer">
        <div class="right_sec">
          <carousel
            [cellsToShow]="1"
            [cellsToScroll]="1"
            [arrows]="true"
            [dots]="true"
          >
            <div class="carousel-cell bx_wrapper">
              <h2><span>Web</span> Technologies</h2>
              <p>
                Make your product stand out with an appealing and interactive
                interface built by the ASZ team of experts! Our proficient
                developers utilize the best front-end and back-end technologies
                to provide you with solutions that cater to your requirements..
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/web_1.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_2.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_3.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_5.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_6.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_7.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_8.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/web_9.svg"
                    class="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>Mobile</span> Technologies</h2>
              <p>
                Make your product stand out with an appealing and interactive
                interface built by the ASZ team of experts! Our proficient
                developers utilize the best front-end and back-end technologies
                to provide you with solutions that cater to your requirements..
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/mobile_1.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/mobile_2.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/mobile_3.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/mobile_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/android.svg"
                    class="img-fluid"
                    alt="android"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/img_204828.png"
                    class="img-fluid"
                    alt="ios"
                  />
                </li>
              </ul>
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>Desktop</span> Technologies</h2>
              <p>
                The full set of services around development and maintenance of
                complex business-critical applications. Our experts build, test,
                deploy, protect, manage, migrate and optimize enterprise-scale
                digital solutions ensuring they’re always up and running and
                achieve the optimal TCO.
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_1.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_2.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_3.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/desktop_5.svg"
                    class="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>Database</span></h2>
              <p>
                Secure your data on your trusted databases. Data organization is
                the practice of categorizing and classifying data to make it
                more usable
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/database_1.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_2.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_3.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_5.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_6.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/database_7.svg"
                    class="img-fluid"
                  />
                </li>
              </ul>
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>DevOps </span></h2>
              <p>
                Bridge the gap between operations and development with our
                outstanding DevOps services. With the latest tools in our tech
                stack, we are capable of meeting any of your requests..
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_1.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_2.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_3.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_4.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_5.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/devops_6.svg"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/github.svg"
                    class="img-fluid"
                    alt="github"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/bitbucket.webp"
                    class="img-fluid"
                    alt="bitbuket"
                  />
                </li>
              </ul>
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>Cloud</span> Services</h2>
              <p>
                Gain greater flexibility, build cost-effective software, benefit
                from improved agility and enhance performance with our
                high-quality cloud services. We use cutting-edge tools to
                provide you with smart cloud solutions..
              </p>
              <ul>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_1.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_2.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_3.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_4.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_5.webp"
                    class="img-fluid"
                  />
                </li>
                <li>
                  <img
                    src="../../../assets/img/asz/cloud_6.webp"
                    class="img-fluid"
                  />
                </li>
              </ul>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="happy_clients">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="happy_clients_sec">
          <div class="box_item"></div>
          <div class="box_item"></div>
          <div class="box_item"></div>
          <div class="box_item"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="presence">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="presence_blog no_border">
          <h2>11</h2>
          <h4>Years of Presence</h4>
          <p>In Competitive Market</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="presence_blog">
          <h2>60+</h2>
          <h4>Happy Customers</h4>
          <p>On every manners</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="presence_blog">
          <h2>250+</h2>
          <h4>Projects Delivered</h4>
          <p>On various technologies</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="presence_blog">
          <h2>10+</h2>
          <h4>Country Served</h4>
          <p>from all around the world</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="clients_sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="heading">
          <h2><span>We Support</span> Customers Around The Globe</h2>
        </div>
        <div class="client_list for_web">
          <carousel [cellsToShow]="5" [cellsToScroll]="1" [arrows]="false" [autoplay] = "true" [loop]="true" [autoplayInterval] = "2000" [transitionDuration] = "500">
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-1.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-16.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-2.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-3.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-4.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-5.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-6.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-7.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-8.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-9.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-10.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-11.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-12.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-13.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-14.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-15.png"
                class="img-fluid"
              />
            </div>
            
          </carousel>
        </div>
        <div class="client_list for_mob">
          <carousel [cellsToShow]="2" [cellsToScroll]="1" [arrows]="false">
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-1.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-16.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-2.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-3.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-4.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-5.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-6.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-7.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-8.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-9.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-10.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-11.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-12.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-13.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-14.png"
                class="img-fluid"
              />
            </div>
            <div class="carousel-cell">
              <img
                src="../../../assets/img/asz/client-15.png"
                class="img-fluid"
              />
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="call-to-action-section">
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-12">
        <div class="action_sec">
          <img
            src="../../../assets/img/asz/dot-line-white-03.svg"
            class="action_left_fiximg"
          />
          <img
            src="../../../assets/img/asz/line-wave-white.svg"
            class="action_right_fiximg"
          />
          <div class="call-to-action_content">
            <!-- <p>We offer solutions with client centric approach </p> -->
            <h4>We offer solutions with client centric approach</h4>
            <a routerLink="/contact-us">
              Get in Touch &nbsp;&nbsp;<i class="fas fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</section>
