<section class="heading_banner">
    <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="banner_content">
                        <span class="mt-4">UI/UX</span>
                        <h2>Graceful <span class="color-y">UI/UX</span> that Users Love</h2>
                        <p>From initial conceptualization to design and development, we provide end-to-end UI UX services to shape your digital platform for best engagement and long-term success.
                        </p>
                    </div>
                </div>
            </div>
    </div>
</section>









<!-- 
<section class="about-header-section">
    <div class="banner_bg_img">
        <img src="../../../../assets/img/asz/round-dot2.svg" class="img-fluid" />
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="contact_banner">
                    <span>UI/UX</span>
                    <h1>Graceful UI/UX that Users Love</h1>
                    
                        <p>From initial conceptualization to design and development, we provide end-to-end UI UX services to shape your digital platform for best engagement and long-term success.</p>
                    
                </div>
            </div>
        </div>
    </div>
</section> -->



<!--Waves Container-->
<!-- <div class="headerwaves">

    <div>
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
        </svg>
    </div>


</div> -->
<!--Waves end-->







 <!-- app two feature three start -->
 <section class="app-two-feature-three ptb-60">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-12 col-xl-12 col-sm-12">
                <div class="section-heading" data-aos="fade-up">
                    <div class="heading">
                    <h2><span>UI/UX</span> Design  Services </h2>   
                </div>
                <div class="service-topics" >               
                <ul>
                    <li class="li-0">
                        <h1 class="strokeme">1.</h1>
                    <h5>UI/UX design research</h5>
                    <p>Our professionals communicate with the primary/secondary stakeholders of a company along with the users during detailed research.</p>
                    </li>

                    <li class="li-0">
                        <h1 class="strokeme">2.</h1>
                    <h5>UX design</h5>
                    <p>We incorporate the requirements of our end-users to create interactive and unique UX design patterns.</p>
                    </li>


                    <li class="li-0">
                        <h1 class="strokeme">3.</h1>
                    <h5>UX strategy</h5>
                    <p>We gather inputs from human elements and learn about the desired outcomes to create a practical UX strategy.</p>
                    </li>


                    <li class="li-0">
                        <h1 class="strokeme">4.</h1>
                    <h5>UI and UX consulting</h5>
                    <p>We analyze the necessities of our clients in detail through consultation to create relevant and attractive designs.</p>
                    </li>


                    <li class="li-0">
                        <h1 class="strokeme">5.</h1>
                    <h5>Mobile App UX and UI Design</h5>
                    <p>Our professionals have the expertise to create feature-rich UI/UX designs for the mobile apps of any size.</p>
                    </li>


                    <li class="li-0">
                        <h1 class="strokeme">6.</h1>
                    <h5>Cross platform experiences design</h5>
                    <p>We focus on the main principles of UI design and create responsive designs with cross-platform compatibility.</p>
                    </li>
                    </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-5 col-xl-5 col-sm-12">
                <div class="app-two-feature-three-img position-relative text-center mt-lg-5  mt-lg-0">
                    <div class="feature-three-shape round_img">
                        <img src="../../../assets/img/asz/app-two-feature-blob-shapen.png" alt="app screen" class="feature-three-blob img-fluid" />
                    </div>
                    <img src="../../../assets/img/asz/web-design.png" alt="app screen" class="updown-ani position-relative z-5" />
                    
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- app two feature three end -->








<section class="app-two-cta ptb-120 text-white bg-blue">
      <div class="container">
          <div class="row align-items-center justify-content-lg-between">
              <div class="col-lg-6 col-xl-6">
                  <div class="position-relative text-center pe-5"  data-aos="fade-down">
                      <img src="assets/img/asz/22.png" alt="" class="img-fluid position-relative z-5" />
                  </div>
              </div>
              <div class="col-lg-6 col-xl-6 col-md-10">
                  <div class="app-two-cta-right px-md-0 pt-5 pt-md-0">
                      <div class="section-heading">
                          <h2 class="">
                            Shape Up Your Brand With The Top Rated Web Design Agency
                          </h2>
                          <p>
                            Our unique approach to Web Design involves research and discussion. Our representatives will meet you to discuss brand aspirations and requirements in detail. We conduct deep market research to understand your industry and competition before design concept development. We use our findings from consultation to refine the design ideas. Further, we refine and revise as per your needs.
                          </p>
                      </div>
                      <div class="cta-count">
                          <ul class="list-unstyled d-flex">
                              <li class="me-4">
                                  <h3 class="mb-0">500+</h3>
                                  <span>Logos</span>
                              </li>
                              <li class="me-4">
                                  <h3 class="mb-0">150+</h3>
                                  <span>Brochures</span>
                              </li>
                              <li class="me-4">
                                  <h3 class="mb-0">450+</h3>
                                  <span>Newsletters</span>
                              </li>
                              <li class="me-4">
                                <h3 class="mb-0">300+</h3>
                                <span>Landing Pages</span>
                            </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>









<section class="app-two-feature pb-60 pt-60">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div class="heading">
                    <h2><span>UI/UX </span> Process</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6 design-text-s">
                <h4 m>Design and experience shape the user’s perception</h4>
                <p><strong>The more simple and intuitive, the more valuable it is.</strong> We follow an iterative approach to design and make sure that the complexity of the problem is truly dispersed.</p>
                <p><strong>UI and UX Design is a cross functional activity.</strong>Our team members have exposure to multiple domains which helps us remain in sync with the business objectives and constraints while operating.</p>
                <p><strong>Empathy is the ultimate driver of delightful experience.</strong> We design as if we are building for ourselves, our friends and family. This keeps the bar high.</p>
                <p><strong>Consistency and reliability are the key to great design.</strong> We follow a rigorous approach when it comes to evaluating our designs, this ensures consistency and reliability in the output.</p>
            </div>
            <div class="col-md-6 updown-img"  data-aos="fade-up">
                <img src="../../../assets/img/asz/ui-ux-pro3.png" class="img-fluid  position-relative z-5" />
            </div>
        </div>
    </div>
</section>




<section class="cta-with-feature ptb-60"
    style="background: url('assets/img/asz/lbg.PNG')no-repeat; background-size: cover;">
    <div class="container">
        <div class="bg-blue text-black rounded-custom position-relative">
            <div class="row">
                <div class="col-lg-7 col-md-10 order-lg-2">
                    <div class="cta-with-feature-wrap plr-5 text-white">

                        <div class="heading" data-aos="fade-down" data-aos-duration="800"
                            data-aos-easing="ease-out-cubic">
                            <h2><span>Our</span> Expertise</h2>
                        </div>
                        <div class="cta-feature-list mb-0 mt-3" data-aos="fade-up" data-aos-duration="800"
                            data-aos-easing="ease-out-cubic">
                            <p class="py-1 text-dark d-flex">
                                <i class="fa fa-check-circle me-2"></i>
                               <span>
                                <strong>Mobile App Design:</strong><br/>
                                We do mobile design for android and iOS, for
                               consumer as well as enterprise applications.
                               </span>
                            </p>
                            <p class="py-1 text-dark d-flex">
                                <i class="fa fa-check-circle me-2"></i>
                                <span>
                                <strong>Web Design:</strong><br/>
                                We design web based solutions for our clients, our work ranges from
                                consumer e-commerce design to enterprise application design.
                                </span>
                            </p>
                            <p class="py-1 text-dark d-flex"> 
                                <i class="fa fa-check-circle me-2"></i>
                                <span>
                                    <strong>Dashboard Design:</strong><br/>
                                We design intuitive dashboards for our clients so
                                that they get better insights faster and make right decisions.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 order-lg-1">
                    <img class="img-fluid p-2" src="assets/img/asz/uix.png" alt="paper-plane" data-aos="fade-left"
                        data-aos-duration="800" data-aos-easing="ease-out-cubic">
                </div>
            </div>
        </div>
    </div>
</section>