<section class="about-header-section pt-120 mob-pt position-relative overflow-hidden">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-heading-wrap d-flex justify-content-between align-items-center z-5 position-relative">
                    <div class="about-content-left" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                        <div class="about-info mb-5" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                            <h1 class="fw-bold display-5">Terms & Conditions</h1>
                            <p class="lead">ASZ Technologies Lorem Ipsum is simply dummy printing and typesetting industry.</p>
                                
                            
                        </div>
                        <!-- <img src="assets/img/company-img1.png" alt="about" class="img-fluid about-img-first mt-5 rounded-custom shadow"> -->
                    </div>
                    <div class="about-content-right" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                        <img src="assets/img/terms-conditions.PNG" alt="about" class="img-fluid mb-5 rounded-custom" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic"> 
                        <!-- <img src="assets/img/about-img-3.jpg" alt="about" class="rounded-custom about-img-last shadow"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="bg-white position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z-2 py-5">
    </div> -->
</section>

<section class="support-content ptb-120">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-lg-4 col-md-4 mb-5 d-none d-md-block">
                <div class="support-article-sidebar sticky-sidebar"  data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="800">
                    <div class="nav flex-column nav-pills support-article-tab bg-light rounded-custom p-3" id="v-pills-support" role="tablist" aria-orientation="vertical" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <button class="nav-link active" data-bs-target="#support-tab-1" data-bs-toggle="pill" type="button" role="tab" aria-selected="true">YOUR AGREEMENT</button>
                        <button class="nav-link" data-bs-target="#support-tab-2" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">PRIVACY</button>
                        <button class="nav-link" data-bs-target="#support-tab-3" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">LINKED SITES</button>
                        <button class="nav-link" data-bs-target="#support-tab-4" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">FORWARD LOOKING STATEMENTS</button>
                        <button class="nav-link" data-bs-target="#support-tab-5" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">EXCLUSIONS AND LIMITATIONS</button>
                        <button class="nav-link" data-bs-target="#support-tab-6" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">OUR PROPRIETARY RIGHTS</button>
                        <button class="nav-link" data-bs-target="#support-tab-7" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">INDEMNITY</button>
                        <button class="nav-link" data-bs-target="#support-tab-8" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">COPYRIGHT & NOTICE</button>
                        <button class="nav-link" data-bs-target="#support-tab-9" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">PLACE OF PERFORMANCE</button>
                        <button class="nav-link" data-bs-target="#support-tab-10" data-bs-toggle="pill" type="button" role="tab" aria-selected="false">GENERAL</button>
                    </div>
                </div>
            </div>
    <!-- for mobile  -->
            <div class="col-lg-4 col-md-4 mb-5 d-md-none">
                <div class="support-article-sidebar sticky-sidebar z-index-1"  data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="800">
                    <div class="w-100 bg-light" ngbDropdown #myDrop="ngbDropdown">
                        <button type="button" class="btn btn-outline-primary w-100 me-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">YOUR AGREEMENT</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownManual" class="w-100 left-0">
                        <button ngbDropdownItem>YOUR AGREEMENT</button>
                        <button ngbDropdownItem>PRIVACY</button>
                        <button ngbDropdownItem>LINKED SITES</button>
                        <button ngbDropdownItem>FORWARD LOOKING STATEMENTS</button>
                        <button ngbDropdownItem>EXCLUSIONS AND LIMITATIONS</button>
                        <button ngbDropdownItem>OUR PROPRIETARY RIGHTS</button>
                        <button ngbDropdownItem>INDEMNITY</button>
                        <button ngbDropdownItem>COPYRIGHT & NOTICE</button>
                        <button ngbDropdownItem>PLACE OF PERFORMANCE</button>
                        <button ngbDropdownItem>GENERAL</button>
                        </div>
                      </div>
                </div>
            </div>
       <!-- for mobile end -->         

            <div class="col-lg-8 col-md-8">
                <div class="tab-content" id="v-pills-supportContent"  data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="800">
                    <div class="tab-pane fade show active" id="support-tab-1" role="tabpanel">
                        <div class="support-article-wrap">
                            <h2 data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">YOUR AGREEMENT</h2>
                            <ul class="support-article-list list-unstyled mt-4">
                                <li class="py-2 border-top border-light" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                                    <p>By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use.</p>
                                    
                                </li>
                                <li class="py-2" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                                    <p>PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.</p>
                                </li>
                                <li>
                                    <ul class="content-list list-unstyled">
                                        <li>COPYRIGHT AND TRADEMARK NOTICE</li>
                                        <li>INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS</li>
                                        <li>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</li>
                                        <li>FORWARD LOOKING STATEMENTS</li>
                                    </ul>
                                </li>

                                <li class="py-2" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                                    <p>This Site may contain links to other independent third-party Web sites ("Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.</p>
                                </li>

                                <li class="py-2" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                                    <p> THIS SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE DOES NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE MATERIALS OR THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED OR DISTRIBUTED THROUGH THE SITE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (i) YOUR USE OF THE SITE, INCLUDING ANY RELIANCE ON ANY SUCH OPINION, ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION CONTAINED HEREIN, SHALL BE AT YOUR SOLE RISK; (ii) THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS; (iii) EXCEPT AS EXPRESSLY PROVIDED HEREIN WE DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, TITLE AND NON-INFRINGEMENT; (iv) WE MAKE NO WARRANTY WITH RESPECT TO THE RESULTS THAT MAY BE OBTAINED FROM THIS SITE, THE PRODUCTS OR SERVICES ADVERTISED OR OFFERED OR MERCHANTS INVOLVED; (v) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK; and (vi) YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</p>
                                </li>

                                <li class="py-2" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                                    <p> YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, ANY OF OUR SITES OR MATERIALS OR FUNCTIONS ON ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</p>
                                </li>


                                <li>
                                    <ol class="d">
                                        <li>By using the Site web sites you agree to indemnify us and affiliated entities.</li>
                                        <li>hold them harmless from any and all claims and expenses, including (with limitation).</li>
                                        <li>arising from your use of the Site web sites, your use of the Products and Services, or your submission of ideas</li>
                                      </ol>
                                </li>

                                <li class="py-2" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                                    <p>It is our policy to respond expeditiously to claims of intellectual property infringement. We will promptly process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act ("DMCA") and other applicable intellectual property laws. Notices of claimed infringement should be directed to:</p>
                                </li>
                                                              
                            </ul>
                            
                        </div>
                    </div>
                    <div class="tab-pane fade" id="support-tab-2" role="tabpanel">
                        <div class="support-article-wrap">
                            <h2>Outbound Call Center</h2>
                            <ul class="support-article-list list-unstyled">
                                <li class="py-4 border-top border-light">
                                    <a href="support-single.html" class="text-decoration-none d-block text-muted">
                                        <h3 class="h5 support-article-title">Appointment Setting</h3>
                                        <p>Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                                        
                                    </a>
                                </li>
                                <li class="py-4 border-top border-light">
                                    <a href="support-single.html" class="text-decoration-none d-block text-muted">
                                        <h3 class="h5 support-article-title">Telemarketing</h3>
                                        <p>Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices ...</p>
                                        
                                    </a>
                                </li>
                                <li class="py-4 border-top border-light">
                                    <a href="support-single.html" class="text-decoration-none d-block text-muted">
                                        <h3 class="h5 support-article-title">Lead Generation & Collections</h3>
                                        <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                                        
                                    </a>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="support-tab-3" role="tabpanel">
                        <div class="support-article-wrap">
                            <h2>Business Process</h2>
                            <ul class="support-article-list list-unstyled">
                                <li class="py-4 border-top border-light">
                                    <a href="support-single.html" class="text-decoration-none d-block text-muted">
                                        <h3 class="h5 support-article-title">Financial Accounting</h3>
                                        <p>Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...</p>
                                        
                                    </a>
                                </li>
                                <li class="py-4 border-top border-light">
                                    <a href="support-single.html" class="text-decoration-none d-block text-muted">
                                        <h3 class="h5 support-article-title">HR Services</h3>
                                        <p>Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices ...</p>
                                        
                                    </a>
                                </li>
                                <li class="py-4 border-top border-light">
                                    <a href="support-single.html" class="text-decoration-none d-block text-muted">
                                        <h3 class="h5 support-article-title">Data Processing & Chat Email Support</h3>
                                        <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                                        
                                    </a>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="support-tab-4" role="tabpanel">
                        <div class="support-article-wrap">
                            <h2>Knowledge Process</h2>
                            <ul class="support-article-list list-unstyled">
                                <li class="py-4 border-top border-light">
                                    <a href="support-single.html" class="text-decoration-none d-block text-muted">
                                        <h3 class="h5 support-article-title">Business & Market Research</h3>
                                        <p>Rapidiously incubate alternative infrastructures with high standards in e-markets. Intrinsicly evisculate e-business best practices standardized...</p>
                                        
                                    </a>
                                </li>
                                <li class="py-4 border-top border-light">
                                    <a href="support-single.html" class="text-decoration-none d-block text-muted">
                                        <h3 class="h5 support-article-title">Content Development & Management Training.</h3>
                                        <p> Intrinsicly evisculate e-business best practices productivate standardized convergence. Credibly evisculate mission-critical ...</p>
                                        
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

