<section class="consulting-header-section">
    <div class="banner_bg_img">
        <img src="../../../../assets/img/asz/round-dot2.svg" class="img-fluid" />
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact_banner">
                    <!-- <span>Consulting</span> -->
                    <h1>IT Consulting</h1>
                    <p data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">Hire experienced
                        technology experts and software developers for your business requirements. We fully
                        integrate into clients' business to get maximum interaction and bring it to the next level.</p>
                    <img src="../../../assets/img/sharepoint-1.jpg" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>

<!--feature section start-->
<section class="feature-section outsourcing-sec pt-120 pb-60">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-1 mb-7 mb-lg-0">
                <div class="mb-4" data-aos="zoom-in-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                    <div class="explore_offering mt-0" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <h2><span>IT </span>Consulting </h2>
                      </div>
                    <p class="text-dark">IT consulting services are advisory services that help clients assess different technology strategies and, in doing so, align their technology strategies with their business or process strategies. These services support customers’ IT initiatives by providing strategic, architectural, operational and implementation planning. Strategic planning includes advisory services that help clients assess their IT needs and formulate system implementation plans. Architecture planning includes advisory services that combine strategic plans and knowledge of emerging technologies to create the logical design of the system and the supporting infrastructure to meet customer requirements.</p>
                </div>
                <ul class="list-unstyled">
                    <li>
                        <i class="fas fa-check text-primary"></i>
                        <span>Digital Conference</span>
                    </li>
                    <li>
                        <i class="fas fa-check text-primary"></i>
                        <span>Great Speak</span>
                    </li>
                    <li>
                        <i class="fas fa-check text-primary"></i>
                        <span>Event Mangement</span>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 order-lg-2">
                <div class="pr-lg-4 position-relative">
                    <div class="bg-orange text-center rounded-custom overflow-hidden p-lg-5 p-4 mx-lg-auto"
                        data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <img src="assets/img/about.jpg" alt="" class="img-fluid rounded-custom shadow-sm">
                        <div
                            class="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="area" >
        <ul class="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
        </ul>
</div >
</section>
<!--feature section end-->

<section class="why-choose-one">
    <div class="offer-sec">
        <div class="position-relative">
        <div class="why-choose-one-img wow slideInRight animated animated animated animated animated animated animated animated animated inline-s1"
        data-wow-delay="100ms" data-wow-duration="2500ms">
        <img src="../../../../assets/img/why-choose-one-img.png" alt="alt">
       </div>
    </div>
    <div class="offer-imges">
    <div class="why-choose-one-shape-1 animated-dev inline-s2">
    </div>
    <div class="why-choose-one-shape-2 animated-dev float-bob-y">
        <img src="../../../../assets/img/why-choose-one-shape-2.png" alt="">
    </div>
    <div class="why-choose-one-shape-3 animated-dev float-bob-x">
        <img src="../../../../assets/img/why-choose-one-shape-3.png" alt="alt">
    </div>
    <div class="why-choose-one-shape-4 animated-dev float-bob-y">
        <img src="../../../../assets/img/why-choose-one-shape-4.png" alt="alt">
    </div>
    <div class="why-choose-one-shape-5 animated-dev float-bob-y">
        <img src="../../../../assets/img/why-choose-one-shape-5.png" alt="alt">
    </div>
    <div class="why-choose-one-shape-6 animated-dev float-bob-x">
        <img src="../../../../assets/img/why-choose-one-shape-6.png" alt="alt">
    </div>
   
</div>
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-7">
                <div class="why-choose-one__left">
                    <div class="section-title text-left">
                        <!-- <div class="section-sub-title-box">
                            <p class="section-sub-title">WHY CHOOSE</p>
                            <div class="section-title-shape-1">
                                <img src="http://layerdrops.com/insurwp/wp-content/uploads/2022/07/section-title-shape-3.png"
                                    alt="alt">
                            </div>
                            <div class=" section-title-shape-2">
                                <img src="http://layerdrops.com/insurwp/wp-content/uploads/2022/07/section-title-shape-4.png"
                                    alt="alt">
                            </div>
                        </div> -->
                        <div class="explore_offering" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                            <h2><span>What we offer</span></h2>
                          </div>
                    </div>
                    <p class="why-choose-one__text">We offer IT consulting services that will help you improve your software architecture, create a tech-driven digital strategy, and improve operations by optimising your software portfolio. Our software engineers will finish your digital transformation journey through careful planning and effective execution of the outlined IT strategy.</p>
                    <div class="why-choose-one__list-box">
                        <ul class="list-unstyled why-choose-one__list">
                            <li>
                                <div class="why-choose-one__single">
                                    <div class="why-choose-one__list-icon insur-icon-svg">
                                        <span aria-hidden="true" class="  icon-easy-to-use"></span>
                                    </div>
                                    <div class="why-choose-one__list-title-box">
                                        <div class="why-choose-one__list-title-inner">
                                            <h3 class="why-choose-one__list-title">Digital Transformation Consulting</h3>
                                        </div>
                                        <!-- <div class="why-choose-one__list-text-box">
                                            <p class="why-choose-one__list-text">Lorem ipsum dolor sit amet, sectetur
                                                adipiscing elit.</p>
                                        </div> -->
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="why-choose-one__single">
                                    <div class="why-choose-one__list-icon insur-icon-svg">
                                        <span aria-hidden="true" class="   icon-contract"></span>
                                    </div>
                                    <div class="why-choose-one__list-title-box">
                                        <div class="why-choose-one__list-title-inner">
                                            <h3 class="why-choose-one__list-title">Project Management Consulting</h3>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="why-choose-one__single">
                                    <div class="why-choose-one__list-icon insur-icon-svg">
                                        <span aria-hidden="true" class="   icon-policy"></span>
                                    </div>
                                    <div class="why-choose-one__list-title-box">
                                        <div class="why-choose-one__list-title-inner">
                                            <h3 class="why-choose-one__list-title">Digital Crisis Management Consulting</h3>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="why-choose-one__single">
                                    <div class="why-choose-one__list-icon insur-icon-svg">
                                        <span aria-hidden="true" class="   icon-fund"></span>
                                    </div>
                                    <div class="why-choose-one__list-title-box">
                                        <div class="why-choose-one__list-title-inner">
                                            <h3 class="why-choose-one__list-title">IT Service Management Consulting</h3>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="why-choose-one__single">
                                    <div class="why-choose-one__list-icon insur-icon-svg">
                                        <span aria-hidden="true" class="   icon-fund"></span>
                                    </div>
                                    <div class="why-choose-one__list-title-box">
                                        <div class="why-choose-one__list-title-inner">
                                            <h3 class="why-choose-one__list-title">Solution consulting</h3>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="why-choose-one__single">
                                    <div class="why-choose-one__list-icon insur-icon-svg">
                                        <span aria-hidden="true" class="   icon-fund"></span>
                                    </div>
                                    <div class="why-choose-one__list-title-box">
                                        <div class="why-choose-one__list-title-inner">
                                            <h3 class="why-choose-one__list-title">Platform consulting</h3>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

<!-- <section class="services-icon ptb-120"
    style="background: url('assets/img/section-bg/section-bg20.webp')no-repeat; background-size: cover; background-position: center bottom; ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="section-heading text-center" data-aos="fade-down" data-aos-duration="800"
                    data-aos-easing="ease-out-cubic">
                    <h2>What we offer</h2>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting the printing and industry.
                    </p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 p-0" data-aos="fade-down-right" data-aos-duration="800"
                data-aos-easing="ease-out-cubic">
                <div class="single-service plr-lg-4 pt-lg-5 pb-lg-5 p-4 text-center mt-3 border-bottom border-end">
                    <div class="service-icon icon-center">
                        <img src="assets/img/service/coding.png" alt="service icon" width="65" height="65" />
                    </div>
                    <div class="service-info-wrap">
                        <h3 class="h5">Organizational Development</h3>
                        <p>
                            Lorem Ipsum is simply dummy<br /> text of the printing and typesetting industry. Lorem Ipsum
                            has been.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 p-0" data-aos="fade-down" data-aos-duration="800"
                data-aos-easing="ease-out-cubic">
                <div class="single-service plr-lg-4 pt-lg-5 pb-lg-5 p-4 text-center mt-3 border-bottom border-end">
                    <div class="service-icon icon-center">
                        <img src="assets/img/service/app-development.png" alt="service icon" width="65" height="65" />
                    </div>
                    <div class="service-info-wrap">
                        <h3 class="h5">Recruitment and Branding</h3>
                        <p>
                            Lorem Ipsum is simply dummy<br /> text of the printing and typesetting industry. Lorem Ipsum
                            has been.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 p-0" data-aos="fade-down-left" data-aos-duration="800"
                data-aos-easing="ease-out-cubic">
                <div class="single-service plr-lg-4 pt-lg-5 pb-lg-5 p-4 text-center mt-3 border-bottom">
                    <div class="service-icon icon-center">
                        <img src="assets/img/service/shield.png" alt="service icon" width="65" height="65" />
                    </div>
                    <div class="service-info-wrap">
                        <h3 class="h5">HR Outsourcing</h3>
                        <p>
                            Lorem Ipsum is simply dummy<br /> text of the printing and typesetting industry. Lorem Ipsum
                            has been.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 p-0" data-aos="fade-up-right" data-aos-duration="800"
                data-aos-easing="ease-out-cubic">
                <div class="single-service plr-lg-4 pt-lg-5 pb-lg-5 p-4 text-center border-end">
                    <div class="service-icon icon-center">
                        <img src="assets/img/service/curve.png" alt="service icon" width="65" height="65" />
                    </div>
                    <div class="feature-info-wrap">
                        <h3 class="h5">Human Resource Effectiveness</h3>
                        <p>
                            Lorem Ipsum is simply dummy<br /> text of the printing and typesetting industry. Lorem Ipsum
                            has been.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 p-0" data-aos="fade-up" data-aos-duration="800"
                data-aos-easing="ease-out-cubic">
                <div class="single-service plr-lg-4 pt-lg-5 pb-lg-5 p-4 text-center border-end">
                    <div class="service-icon icon-center">
                        <img src="assets/img/service/graphic-design.png" alt="service icon" width="65" height="65" />
                    </div>
                    <div class="feature-info-wrap">
                        <h3 class="h5">Employee Handbooks and Policies</h3>
                        <p>
                            Lorem Ipsum is simply dummy<br /> text of the printing and typesetting industry. Lorem Ipsum
                            has been.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 p-0" data-aos="fade-up-left" data-aos-duration="800"
                data-aos-easing="ease-out-cubic">
                <div class="single-service plr-lg-4 pt-lg-5 pb-lg-5 p-4 text-center">
                    <div class="service-icon icon-center">
                        <img src="assets/img/service/promotion.png" alt="service icon" width="65" height="65" />
                    </div>
                    <div class="feature-info-wrap">
                        <h3 class="h5">Organizational Development</h3>
                        <p>
                            Lorem Ipsum is simply dummy<br /> text of the printing and typesetting industry. Lorem Ipsum
                            has been.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->




<!--promo section start-->
<section class="promo-section ptb-60 bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center">
                        <div class="explore_offering" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                            <h2><span>Everything We Do </span> it With Love</h2>
                          </div>
                    <p data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">Progressively
                        deploy market positioning catalysts for change and technically sound
                        authoritatively e-enable resource-leveling infrastructures. </p>
                </div>
            </div>
        </div>
        <div class="project-row">
                <div class="promo-card-wrap"
                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">250+</h3>
                        <h2>Completed Projects</h2>
                        <p class="mb-0">Rapidiously embrace distinctive best practices after B2B syndicate. </p>
                    </div>
                </div>
                <div class="promo-card-wrap bg-clr2"
                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">60+</h3>
                        <h2>Happy Client</h2>
                        <p class="mb-0">Energistically impact synergistic convergence for leadership..</p>
                    </div>
                </div>
                <div class="promo-card-wrap bg-clr3"
                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">90%</h3>
                        <h2>24/7 Support</h2>
                        <p class="mb-0">Synergistically revolutionize leadership whereas platform. </p>
                    </div>
                </div>
        </div>
    </div>
</section>
<!--promo section end-->
