<!--page header section start-->
<section class="page-header position-relative overflow-hidden pt-160 pb-80 bg_dark"
    style="background: url('assets/img/page-header-bg.svg')no-repeat bottom left">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <h1 class="display-5 fw-bold">Privacy Policy</h1>
                <p class="lead">We understand that your personal information is important to you and we manage it with great care.</p>
            </div>
        </div>
        <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg_dark-light right-5"></div>
    </div>
</section>
<!--page header section end-->

<!--feature section start-->
<section class="feature-section ptb-120">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                <div class="mb-4" >
                    <h2 data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">Responsible and ethical engagement</h2>
                    <p data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">We are committed to participating in the political and public policy process in a responsible and ethical way that serves the best interests of our shareholders, employees, customers and the communities in which we operate.</p>
                </div>
                <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5">
                    <li data-aos="fade-up-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <span class="mb-15"><img src="assets/img/ethics-icon.png"/></span>
                        <h3 class="h5">Business Ethics</h3>
                        <p>Objectively exceptional via customized intellectual.</p>
                    </li>
                    <li data-aos="fade-up-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <span class="mb-15"><img src="assets/img/transparancy-icon.png"/></span>
                        <h3 class="h5">Transparency</h3>
                        <p>Interactively integrate extensible directed developer. </p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 order-lg-1">
                <div class="pr-lg-4 mt-md-4 position-relative">
                    <div class="bg-light text-center rounded-custom overflow-hidden p-lg-5 p-4 mx-lg-auto" >
                        <img src="assets/img/ethics.jpg" alt="" class="img-fluid shadow-sm rounded-custom hero_img_1" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                        <div
                            class="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section end-->

<!--feature section start-->
<section class="feature-section section-dark ptb-140">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-1 mb-7 mb-lg-0">
                <div class="mb-4">
                    <h2 data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">Our approach to public policy</h2>
                    <p data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">We believe that the best solutions to the world’s problems require governments, industries, organizations and individuals to work together.  All of these activities must comply with applicable law and Cognizant’s Core Values and Code of Ethics.
                    <br><br>To learn more about our political engagement policies, including board and management oversight, please read our <a href="#">Political Activity Policy</a> </p>
                </div>
                <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5">
                    <li data-aos="fade-up-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <span class="mb-15"><img src="assets/img/data-security.png"/></span>
                        <h3 class="h5">Data Security</h3>
                        <p>Objectively exceptional via customized intellectual.</p>
                    </li>
                    <li data-aos="fade-up-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <span class="mb-15"><img src="assets/img/compliance.png"/></span>
                        <h3 class="h5">Compliance</h3>
                        <p>Interactively integrate extensible directed developer. </p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 order-lg-2">
                <div class="pr-lg-4 position-relative">
                    <div class="bg-light text-center rounded-custom overflow-hidden p-lg-5 p-4 mx-lg-auto" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                        <img src="assets/img/privacy-policy.jpg" alt="" class="img-fluid rounded-custom shadow-sm bg-white" style="height: 400px;">
                        <div class="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section end-->

<!--feature section start-->
<section class="feature-section ptb-120">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                <div class="mb-4" >
                    <h2 data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">Corporate political contributions</h2>
                    <p data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">We are committed to participating in the political and public policy process in a responsible and ethical way that serves the best interests of our shareholders, employees, customers and the communities in which we operate.</p>
                </div>
            
            </div>
            <div class="col-lg-6 order-lg-1">
                <div class="pr-lg-4 mt-md-4 position-relative">
                    <div class="bg-light text-center rounded-custom overflow-hidden p-lg-5 p-4 mx-lg-auto" >
                        <img src="assets/img/hero_analysis1.jpg" alt="" class="img-fluid shadow-sm rounded-custom hero_img_1" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                        <div
                            class="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section end-->

<!--feature section start-->
<section class="feature-section section-dark ptb-140">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-1 mb-7 mb-lg-0">
                <div class="mb-4">
                    <h2 data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">Trade associations</h2>
                    <p data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">We participate in trade associations for a variety of reasons, including networking, building industry skills, civic participation and monitoring of industry policies and trends. Our participation in trade associations, including membership on a trade association board, does not mean that we agree with every position an association may take. At times, trade associations in which we participate may take positions that differ from ours. We disclose in our semi-annual Political Spend Reports the identity of and dues paid to U.S. trade associations to which we have paid annual dues of $50,000 or more. </p>
                </div>
                
            </div>
            <div class="col-lg-6 order-lg-2">
                <div class="pr-lg-4 position-relative">
                    <div class="bg-light text-center rounded-custom overflow-hidden p-lg-5 p-4 mx-lg-auto" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                        <img src="assets/img/hero_analysis2.png" alt="" class="img-fluid rounded-custom shadow-sm bg-white">
                        <div
                            class="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section end-->
