<section class="heading_banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="banner_content">
          <h1>Software Development</h1>
          <p>
            We believe in building powerful software backed by innovative technology that reflects your business style, that’s why we plan carefully and ensure that every feature connects with your users in a meaningful way.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="masonary-blog-section">
  <div class="container">    
    <div class="row">
      <div
        class="col-lg-4 col-md-6"
        data-aos="fade-right"
        data-aos-duration="800"
        data-aos-easing="ease-out-cubic"
      >
        <div class="single-article rounded-custom my-3">
          <a class="article-img">
            <img src="assets/img/img-w1.jpg" alt="article" class="img-fluid" />
          </a>
          <div class="article-content p-4">
            <div class="article-category mb-4 d-block">
              <a
                class="d-inline-block text-dark badge bg-danger-soft"
                >Development</a
              >
            </div>
            <a>
              <h2 class="h5 article-title limit-2-line-text">
                Dynamic Website Development
              </h2>
            </a>
            <p class="limit-2-line-text">
              Get a Dynamic Website Development For Your business With Modern
              Design and complex Functions To Take your Business Online .
            </p>
          </div>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6"
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-easing="ease-out-cubic"
      >
        <div class="single-article rounded-custom my-3">
          <a class="article-img">
            <img src="assets/img/ecomerce.png" alt="article" class="img-fluid" />
          </a>
          <div class="article-content p-4">
            <div class="article-category mb-4 d-block">
              <a
                class="d-inline-block text-dark badge bg-primary-soft"
                >Development</a
              >
            </div>
            <a>
              <h2 class="h5 article-title limit-2-line-text">
                Ecommerce Website Development
              </h2>
            </a>
            <p class="limit-2-line-text">
              Get A Single Vendor or Multivendor Ecommerce Website For Your
              Online Shopping Business And Start Selling With Your New brand
              ecommerce Website .
            </p>

          </div>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-6"
        data-aos="fade-left"
        data-aos-duration="800"
        data-aos-easing="ease-out-cubic"
      >
        <div class="single-article rounded-custom my-3">
          <a class="article-img">
            <img src="assets/img/des4.png" alt="article" class="img-fluid" />
          </a>
          <div class="article-content p-4">
            <div class="article-category mb-4 d-block">
              <a
                class="d-inline-block text-dark badge bg-warning-soft"
                >Development</a
              >
            </div>
            <a>
              <h2 class="h5 article-title limit-2-line-text">
                Corporate Website Development
              </h2>
            </a>
            <p class="limit-2-line-text">
              Do you Have a company, or a Multi National Brand , So we think
              that You need to have a Website For Your corporate . Get a
              Corporate website Development With all Corporate functionality
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->



<section class="feature-section ptb-80">

  <div class="container">
    <div class="row">

      <div class="col-md-6 col-lg-4 mb-5">
        <div class="content">
          <p class="icon">
            <img src="../../../../assets/img/asz/icon-1.png">
          </p>
          <div class="content-text">
            <h3>Embedded Software Development</h3>
            <p>Embedded system software developers have a difficult job. For one, the work itself is challenging
              embedded systems must withstand numerous updates to their environment to function properly. </p>
          </div>
        </div>
      </div>


      <div class="col-md-6 col-lg-4 mb-5">
        <div class="content">
          <p class="icon">
            <img src="../../../../assets/img/asz/icon-2.png">
          </p>
          <div class="content-text">
            <h3>Large-scale Software Development
            </h3>
            <p>Large-scale software development is the multilayered process of developing systems that support multiple
              simultaneous users who access the software functionality at the same time.</p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 mb-5">
        <div class="content">
          <p class="icon">
            <img src="../../../../assets/img/asz/icon-3.png">
          </p>
          <div class="content-text">
            <h3>SaaS & Software Products</h3>
            <p>We offer end-to-end software product engineering & development services. Our teams align your
              requirements and forge in building high quality & scalable products.</p>
          </div>
        </div>
      </div>


      <div class="col-md-6 col-lg-4 mb-5">
        <div class="content">
          <p class="icon">
            <img src="../../../../assets/img/asz/icon-4.png">
          </p>
          <div class="content-text">
            <h3>Enterprise Software Development
            </h3>
            <p>The first thing you should know about this modality of software development is that it is a software
              whose goal is not to meet the needs of a specific person or a particular.</p>
          </div>
        </div>
      </div>



      <div class="col-md-6 col-lg-4 mb-5">
        <div class="content">
          <p class="icon">
            <img src="../../../../assets/img/asz/icon-5.png">
          </p>
          <div class="content-text">
            <h3>Product Development</h3>
            <p>Product development is the complete process of taking an idea from concept to delivery and beyond.
              Whether you are delivering a brand new offering or enhancing an existing product.</p>
          </div>
        </div>
      </div>



      <div class="col-md-6 col-lg-4 mb-5">
        <div class="content">
          <p class="icon">
            <img src="../../../../assets/img/asz/icon-6.png">
          </p>
          <div class="content-text">
            <h3>MVP Development</h3>
            <p>A minimum viable product, or MVP, is a product with enough features to attract early-adopter customers
              and validate a product idea early in the product development cycle.

            </p>
          </div>
        </div>
      </div>



    </div>
  </div>
</section>




<section class="technologies ptb-80">

  <div class="container">
    <div class="row">

      <div class="col-md-12">
        <div class="content-text">
          <h3>Technologies</h3>

          <div class="techno-icon">
            <div>
              <img src="../../../../assets/img/asz/technologies-1.png">
              <h5>java</h5>
            </div>
            <div>
              <img src="../../../../assets/img/asz/technologies-2.png">
              <h5>Node</h5>
            </div>
            <div>
              <img src="../../../../assets/img/asz/technologies-3.png">
              <h5>.NET</h5>
            </div>
            <div>
              <img src="../../../../assets/img/asz/technologies-4.png">
              <h5>C#</h5>
            </div>
            <div>
              <img src="../../../../assets/img/asz/technologies-5.png">
              <h5>PHP</h5>
            </div>
            <div>
              <img src="../../../../assets/img/asz/technologies-6.png">
              <h5>Scala</h5>
            </div>
            <div>
              <img src="../../../../assets/img/asz/technologies-7.png">
              <h5>Python</h5>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>




<!-- <section class="cta-with-feature">
  <div class="container">
    <div class="bg-blue text-black rounded-custom position-relative">
      <div class="row">
        <div class="col-lg-6 col-md-10">
          <div class="cta-with-feature-wrap text-white">
            <h2 class="text-white" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
              Are you Ready to Start your Project?
            </h2>
            <ul class="cta-feature-list list-unstyled d-flex flex-wrap list-two-col mb-0 mt-3" data-aos="fade-right"
              data-aos-duration="800" data-aos-easing="ease-out-cubic">
              <li class="d-flex align-items-center py-1">
                <i class="fa fa-check-circle me-2 text-warning"></i> Software Consulting
              </li>
              <li class="d-flex align-items-center py-1">
                <i class="fa fa-check-circle me-2 text-warning"></i> Custom Software Development
              </li>
              <li class="d-flex align-items-center py-1">
                <i class="fa fa-check-circle me-2 text-warning"></i> Software Development Outsourcing
              </li>
              <li class="d-flex align-items-center py-1">
                <i class="fa fa-check-circle me-2 text-warning"></i> Software Product Development
              </li>
              <li class="d-flex align-items-center py-1">
                <i class="fa fa-check-circle me-2 text-warning"></i> Secure Software Development
              </li>
              <li class="d-flex align-items-center py-1">
                <i class="fa fa-check-circle me-2 text-warning"></i> Software Development Automation
              </li>
              <li class="d-flex align-items-center py-1">
                <i class="fa fa-check-circle me-2 text-warning"></i> Research and Development
              </li>
              <li class="d-flex align-items-center py-1">
                <i class="fa fa-check-circle me-2 text-warning"></i> Embedded Software Development
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-5 project_web">
          <div class="shape-img position-absolute d-none d-lg-block top--40 right--40">
            <img src="assets/img/shape/paper-plane.png" alt="paper-plane" data-aos="fade-right" data-aos-duration="800"
              data-aos-easing="ease-out-cubic" />
          </div>
          <img src="assets/img/asz/project_img.png" alt="bhhbwdhwd" />
        </div>
      </div>
    </div>
  </div>
</section> -->





<section class="technologies_we_ofer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="heading">
          <h2><span>Are you Ready</span><br /> to Start your Project?</h2>
        </div>
      </div>
    </div>
    <div class="tech_tabing">
      <div class="row web_tech_offer">
        <div class="col-md-4 pr-0">
          <div class="left_sec">
            <ul>
              <ng-container *ngFor="let ite of techsec">
                <li>
                  <a (click)="techItem(ite.value)" [ngClass]="{ active: techValue == ite.text }">{{ ite.text }}</a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="col-md-8 pl-0">
          <div class="right_sec">
            <div class="bx_wrapper" *ngIf="techValue == 'Software Consulting'">
              <h2><span>Software</span> Consulting</h2>
              <p>
                Software consulting allows technology professionals to provide valuable insight into e-commerce and
                software programs. Companies can benefit from employing a software consultant to help them make
                technology decisions and update software systems. <br /><br />If you're looking to hire a software
                consultant for your workplace, learning helpful tips may assist you in selecting a consultant who aligns
                with your needs. In this article, we discuss what software consulting is and provide the benefits that a
                company may encounter when using it.
              </p>
              <img src="../../../../assets/img/asz/sof-1.png">
            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'Custom Software Development'">
              <h2><span>Custom Software</span> Development</h2>
              <p>
                Custom software development is the process of designing, creating, deploying and maintaining software
                for a specific set of users, functions or organizations. In contrast to commercial off-the-shelf
                software (COTS), custom software development aims at a narrowly defined set of requirements. COTS
                targets a broad set of requirements, allowing it to be packaged and commercially marketed and
                distributed.
              </p>
              <img src="../../../../assets/img/asz/sof-2.png">
            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'Software Development Outsourcing'">
              <h2><span>Software Development</span> Outsourcing</h2>
              <p>
                Software outsourcing has been one of the most talked-about business strategies as technology is
                advancing at a fantastic speed, and businesses are more competitive than ever. <br /><br />

                Through this guide, we wish to explain in depth what software development outsourcing is about and why
                it is an excellent strategy for your business regarding time, costs, quality, and competitiveness. Let’s
                take a deeper look at what it is and how it may help your company.
              </p>
              <img src="../../../../assets/img/asz/sof-3.png">
            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'Software Product Development'">
              <h2><span>Software Product</span> Development</h2>
              <p>
                Software Product development is the process of creating a new product with some added value and
                features. It can be anything from developing an entire new product, or upgrading an existing product, or
                improving the process, technique, or development methodology of the system. <br /><br /> To put it
                another way, With new developed software products we can bring change in the current market and give
                user a better customer experience. Development and innovation pave the path for new product innovations
                and it will benefit the customers
              </p>
              <img src="../../../../assets/img/asz/sof-4.png">

            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'Secure Software Development'">
              <h2><span>Secure Software </span>Development</h2>
              <p>
                As the pace of modern software development picks up speed, more threat actors are using that rapid
                production of applications as opportunities to attack vulnerabilities in your code. Fortunately, there
                are steps you can take to safeguard your software development lifecycle and improve the security of your
                applications.
              </p>
              <img src="../../../../assets/img/asz/sof-5.png">
            </div>
            <div class="bx_wrapper" *ngIf="techValue == 'Software Development Automation'">
              <h2><span>Software Development</span> Automation</h2>
              <p>
                IT automation is the process of creating software and systems to replace repeatable processes and reduce
                manual intervention. It accelerates the delivery of IT infrastructure and applications by automating
                manual processes that previously required a human touch. <br /><br /> With IT automation, software is
                used to set up and repeat instructions, processes, or policies that save time and free up IT staff for
                more strategic work. With the rise of virtualized networks and cloud services that require rapid,
                complex provisioning, automation is an indispensable strategy for helping IT teams deliver services with
                improved speed, consistency, and security.
              </p>
              <img src="../../../../assets/img/asz/sof-6.png">
            </div>
          </div>
        </div>
      </div>
      <div class="row mob_tech_offer">
        <div class="right_sec">
          <carousel [cellsToShow]="1" [cellsToScroll]="1" [arrows]="false" [dots]="true">
            <div class="carousel-cell bx_wrapper">
              <h2><span>Software</span> Consulting</h2>
              <p>
                Software consulting allows technology professionals to provide valuable insight into e-commerce and
                software programs. Companies can benefit from employing a software consultant to help them make
                technology decisions and update software systems. <br /><br />If you're looking to hire a software
                consultant for your workplace, learning helpful tips may assist you in selecting a consultant who aligns
                with your needs. In this article, we discuss what software consulting is and provide the benefits that a
                company may encounter when using it.
              </p>
              <img src="../../../../assets/img/asz/sof-1.png">
              
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>Custom Software</span> Development</h2>
              <p>
                Custom software development is the process of designing, creating, deploying and maintaining software
                for a specific set of users, functions or organizations. In contrast to commercial off-the-shelf
                software (COTS), custom software development aims at a narrowly defined set of requirements. COTS
                targets a broad set of requirements, allowing it to be packaged and commercially marketed and
                distributed.
              </p>
              <img src="../../../../assets/img/asz/sof-2.png">
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>Software Development</span> Outsourcing</h2>
              <p>
                Software outsourcing has been one of the most talked-about business strategies as technology is
                advancing at a fantastic speed, and businesses are more competitive than ever. <br /><br />

                Through this guide, we wish to explain in depth what software development outsourcing is about and why
                it is an excellent strategy for your business regarding time, costs, quality, and competitiveness. Let’s
                take a deeper look at what it is and how it may help your company.
              </p>
              <img src="../../../../assets/img/asz/sof-3.png">
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>Software Product</span> Development</h2>
              <p>
                Software Product development is the process of creating a new product with some added value and
                features. It can be anything from developing an entire new product, or upgrading an existing product, or
                improving the process, technique, or development methodology of the system. <br /><br /> To put it
                another way, With new developed software products we can bring change in the current market and give
                user a better customer experience. Development and innovation pave the path for new product innovations
                and it will benefit the customers
              </p>
              <img src="../../../../assets/img/asz/sof-4.png">
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>Secure Software </span>Development</h2>
              <p>
                As the pace of modern software development picks up speed, more threat actors are using that rapid
                production of applications as opportunities to attack vulnerabilities in your code. Fortunately, there
                are steps you can take to safeguard your software development lifecycle and improve the security of your
                applications.
              </p>
              <img src="../../../../assets/img/asz/sof-5.png">
            </div>
            <div class="carousel-cell bx_wrapper">
              <h2><span>Software Development</span> Automation</h2>
              <p>
                IT automation is the process of creating software and systems to replace repeatable processes and reduce
                manual intervention. It accelerates the delivery of IT infrastructure and applications by automating
                manual processes that previously required a human touch. <br /><br /> With IT automation, software is
                used to set up and repeat instructions, processes, or policies that save time and free up IT staff for
                more strategic work. With the rise of virtualized networks and cloud services that require rapid,
                complex provisioning, automation is an indispensable strategy for helping IT teams deliver services with
                improved speed, consistency, and security.
              </p>
              <img src="../../../../assets/img/asz/sof-6.png">
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</section>













<section class="run_kinds">
  <div class="container">
    <div class="heading">
      <h2><span>We Follow</span> Our Work Process</h2>
    </div>
    <div class="process-row">
      <div class="process-col">
        <div class="single-service-box">
          <div class="single-service-content">
            <div class="single-service-title">
              <img src="assets/img/asz/web7.png" class="img-fluid">
            </div>
          </div>
        </div>
        <h2>Requirement Gathering</h2>
      </div>

      <div class="process-col">
        <div class="single-service-box">
          <div class="single-service-content">
            <div class="single-service-title">
              <img src="assets/img/asz/web2.png" class="img-fluid">
            </div>
          </div>
        </div>
        <h2>Design </h2>
      </div>
      <div class="process-col">
        <div class="single-service-box">
          <div class="single-service-content">
            <div class="single-service-title">
              <img src="assets/img/asz/web6.png" class="img-fluid">
            </div>
          </div>
        </div>
        <h2>Development </h2>
      </div>
      <div class="process-col">
        <div class="single-service-box">
          <div class="single-service-content">
            <div class="single-service-title">
              <img src="assets/img/asz/web4.png" class="img-fluid">
            </div>
          </div>
        </div>
        <h2>Testing</h2>
      </div>

      <div class="process-col">
        <div class="single-service-box">
          <div class="single-service-content">
            <div class="single-service-title">
              <img src="assets/img/asz/web8.png" class="img-fluid">
            </div>
          </div>
        </div>
        <h2>Deployment</h2>
      </div>

      <div class="process-col">
        <div class="single-service-box before-none">
          <div class="single-service-content">
            <div class="single-service-title">
              <img src="assets/img/asz/web5.png" class="img-fluid">
            </div>
          </div>
        </div>
        <h2>Maintenance / Support</h2>
      </div>
    </div>
  </div>
</section>



<!-- <section class="work-process ptb-120"     style="background: url('assets/img/section-bg/section-bg16.webp')no-repeat; background-size: cover; background-position: center top; ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">
                <div class="section-heading text-center" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                    <h2>We Follow Our Work Process</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                </div>
            </div>
        </div>
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-3" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <div class="process-card-two text-center px-4 py-5 rounded-custom shadow-hover mt-4">
                    <div class="process-icon border-light border border-2 rounded-custom p-3">
                        <i class="fa fa-folder-tree fa-2x"></i>
                    </div>
                    <h3 class="h5">Research</h3>
                    <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <div class="process-card-two text-center px-4 py-5 rounded-custom shadow-hover mt-4">
                    <div class="process-icon border-light border border-2 rounded-custom p-3">
                        <i class="fa fa-bezier-curve fa-2x"></i>
                    </div>
                    <h3 class="h5">Designing</h3>
                    <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <div class="process-card-two text-center px-4 py-5 rounded-custom shadow-hover mt-4">
                    <div class="process-icon border-light border border-2 rounded-custom p-3">
                        <i class="fa fa-layer-group fa-2x"></i>
                    </div>
                    <h3 class="h5">Building</h3>
                    <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <div class="process-card-two text-center px-4 py-5 rounded-custom shadow-hover mt-4">
                    <div class="process-icon border-light border border-2 rounded-custom p-3">
                        <i class="fa fa-truck fa-2x"></i>
                    </div>
                    <h3 class="h5">Deliver</h3>
                    <p class="mb-0">Uniquely pursue compelling initiatives expanded.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->