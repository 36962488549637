
<section class="why-choose-us pb-60 pt-120 mob-pt-120 animate-bg">
    <div class="container">
        <div class="row justify-content-lg-between align-items-center">
            <div class="col-lg-6 col-12 order-1 order-lg-0">
                <div class="feature-img-holder mt-5 mt-lg-0 mt-xl-0">
                    <img src="assets/img/open-source.png" class="img-fluid" alt="feature-image">
                </div>
            </div>
            <div class="col-lg-5 col-12">
                <div class="why-choose-content">
                    <h2>Open Source Products</h2>
                    <p>Open source is a set of principles and practices on how to write software, the most important of which is that the source code is openly available.</p>
                    <ul class="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>OpenERP</li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Alfresco</li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>BonitaSoft</li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Ofbiz</li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Fedena</li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>SugarCRM</li>
                    </ul>                    
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-section pt-120 pb-120">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                <div class="mb-4" data-aos="fade-up">
                    <h2>Product details hadding</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                </div>
            </div>
            <div class="col-lg-6 order-lg-1">
                <div class="pr-lg-4 position-relative" data-aos="fade-up">
                    <!--animated shape start-->
                    
                    <!--animated shape end-->
                    <div class="bg-light text-center rounded-custom overflow-hidden py-3 px-3 mx-lg-auto">
                       
                        <div class="position-relative ">
                            <img class="position-relative z-2 w-100 h-auto" src="assets/img/des3.png" alt="Image Description">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-section pt-60 pb-120">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 mb-7 mb-lg-0">
                <div class="mb-4" data-aos="fade-up">
                    <h2>Product details  hadding</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="pr-lg-4 position-relative" data-aos="fade-up">
                    
                    <div class="bg-light text-center shadow-sm rounded-custom overflow-hidden  py-3 px-3 mx-lg-auto">
                       
                        <div class="position-relative">
                            <img class="position-relative z-2 w-100 h-auto" src="assets/img/des4.png" alt="Image Description">
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="masonary-blog-section ptb-120 bg-light">
    <div class="container">       
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w1.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">                        
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                       
                    <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                        <a href="">
                            <div class="d-flex align-items-center">
                                <div class="avatar-info">
                                    <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                </div>
                            </div>
                        </a>
                        <div class="article-category">
                            <a href="" class="d-inline-block text-dark badge bg-danger-soft">Angular</a>
                        </div>
                    </div>    

                    </div>
                </div>
            </div>
            
            
            <div class="col-lg-3 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w2.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-primary-soft">Bootstrap</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w3.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-success-soft">React</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w1.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">                        
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                       
                    <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                        <a href="">
                            <div class="d-flex align-items-center">
                                <div class="avatar-info">
                                    <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                </div>
                            </div>
                        </a>
                        <div class="article-category">
                            <a href="" class="d-inline-block text-dark badge bg-danger-soft">Angular</a>
                        </div>
                    </div>    

                    </div>
                </div>
            </div>
            
            
            <div class="col-lg-3 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w2.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-primary-soft">Bootstrap</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w3.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-success-soft">React</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w1.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">                        
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                       
                    <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                        <a href="">
                            <div class="d-flex align-items-center">
                                <div class="avatar-info">
                                    <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                </div>
                            </div>
                        </a>
                        <div class="article-category">
                            <a href="" class="d-inline-block text-dark badge bg-danger-soft">Angular</a>
                        </div>
                    </div>    

                    </div>
                </div>
            </div>
            
            
            <div class="col-lg-3 col-md-6">
                <div class="single-article shadow-1 my-3">
                    <a href="" class="pro-img">
                        <img src="assets/img/img-w2.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Prodect nam</h2>
                        </a>
                        <p class="limit-2-line-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                        <div class="d-flex align-items-lg-center justify-content-between  pt-4">                        
                            <a href="">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-info">
                                        <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                    </div>
                                </div>
                            </a>
                            <div class="article-category">
                                <a href="" class="d-inline-block text-dark badge bg-primary-soft">Bootstrap</a>
                            </div>
                        </div> 

                    </div>
                </div>
            </div>
                        
        </div>
    </div>
</section>