<section class="Cybersecurity-header-section overflow-hidden">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div
          class="section-heading-wrap d-flex justify-content-between align-items-center z-5 position-relative"
        >
          <div class="about-content-left">
            <div
              class="about-info"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-easing="ease-out-cubic"
            >
              <div
                class="explore_offering"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-easing="ease-out-cubic"
              >
                <h2 class="color-white">
                  <span> Managing risks, </span> keeping businesses secure
                </h2>
              </div>
              <p class="lead">
                IT firms are preferring IT graduates whose skill sets are
                diverse and who are well-trained in certain technology areas at
                the time of joining.
              </p>
              <!-- <p class="lead">ASZ Technologies is offering you a plethora of IT Courses to choose from with a view to bridge the gap between Industry Requirement and curriculum of educational institutions and also to meet the ever increasing demand for Quality IT professional.</p> -->
            </div>
            <!-- <img src="assets/img/company-img1.png" alt="about" class="img-fluid about-img-first mt-5 rounded-custom shadow"> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="bg-white position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z-2 py-5">
    </div> -->
</section>

<section class="work-process ptb-60">
  <div class="container">
    <div
      class="explore_offering text-center"
      data-aos="fade-right"
      data-aos-duration="800"
      data-aos-easing="ease-out-cubic"
    >
      <h2>
        <span> Guarding the cyber frontier </span> <br />
        (Securing your business)
      </h2>
    </div>
    <div class="row align-items-start justify-content-between">
      <div class="col-lg-5 col-md-12 order-1 order-lg-0">
        <div class="img-wrap">
          <img
            src="assets/img/about_cyber.jpg"
            alt="work process"
            class="img-fluid rounded-custom"
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 order-0 order-lg-1">
        <ul class="work-process-list list-unstyled">
          <li
            class="d-flex align-items-start mb-3"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
          >
            <div
              class="process-icon-2 border border-2 rounded-custom bg-orange me-4 mt-2"
            >
              <i class="fa fa-bezier-curve fa-2x"></i>
            </div>
            <div class="icon-content">
              <h3 class="h5 mb-2">Managed security services</h3>
              <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p> -->
            </div>
          </li>
          <li
            class="d-flex align-items-start mb-3"
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
          >
            <div
              class="process-icon-2 border border-2 rounded-custom bg-orange me-4 mt-2"
            >
              <i class="fa fa-folder-tree fa-2x"></i>
            </div>
            <div class="icon-content">
              <h3 class="h5 mb-2">Compliance testing</h3>
            </div>
          </li>
          <li
            class="d-flex align-items-start mb-3"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
          >
            <div
              class="process-icon-2 border border-2 rounded-custom bg-orange me-4 mt-2"
            >
              <i class="fa fa-mobile fa-2x"></i>
            </div>
            <div class="icon-content">
              <h3 class="h5 mb-2">Information security consulting</h3>
            </div>
          </li>

          <li
            class="d-flex align-items-start mb-3"
            data-aos="fade-right"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
          >
            <div
              class="process-icon-2 border border-2 rounded-custom bg-orange me-4 mt-2"
            >
              <i class="fa fa-layer-group fa-2x"></i>
            </div>
            <div class="icon-content">
              <h3 class="h5 mb-2">Security code review</h3>
            </div>
          </li>
          <li
            class="d-flex align-items-start mb-3"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
          >
            <div
              class="process-icon-2 border border-2 rounded-custom bg-orange me-4 mt-2"
            >
              <i class="fa fa-truck fa-2x"></i>
            </div>
            <div class="icon-content">
              <h3 class="h5 mb-2">Application security implementation</h3>
            </div>
          </li>
          <li
            class="d-flex align-items-start mb-3 mb-lg-0"
            data-aos="fade-left"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
          >
            <div
              class="process-icon-2 border border-2 rounded-custom bg-orange me-4 mt-2"
            >
              <i class="fa fa-truck fa-2x"></i>
            </div>
            <div class="icon-content">
              <h3 class="h5 mb-2">
                Vulnerability assessment and penetration testing
              </h3>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="security-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="security-image">
          <!-- <img src="https://themes.envytheme.com/cyarb/wp-content/uploads/2021/11/web-bg1.jpg"
                            alt="web-security"> -->
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="security-content">
          <div class="content">
            <div
              class="explore_offering"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-easing="ease-out-cubic"
            >
              <h2><span> Complete </span> Website Security</h2>
            </div>

            <p class="p">
              Every business is different, and so are the vulnerabilities. We help you build a flawless, secure web environment, that suits your business needs. We take care of your Website security while you focus on your core competencies. With cutting-edge innovation and thorough evaluation, we ensure that vulnerabilities are found and addressed even before a malicious attack occurs.
            </p>
            <ul class="features-list">
              <li>
                <div class="circle-icon"></div>
                Malware Detection Removal
              </li>
              <li>
                <div class="circle-icon"></div>
                Managing Cloud Security
              </li>
              <li>
                <div class="circle-icon"></div>
                Content Delivery Network
              </li>
              <li>
                <div class="circle-icon"></div>
                Testing Cyber Security
              </li>
              <li>
                <div class="circle-icon"></div>
                Security Management
              </li>
              <li>
                <div class="circle-icon"></div>
                Identifying Threats
              </li>
            </ul>

           
            <div class="shape2">
              <img src="assets/img/shape2.png" alt="Shape" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="solution-area solution-area-three white-bg ptb-80">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="solution-content">
            <div class="solution-title">
              <span class="top-text">All-in Solution</span>
              <div class="explore_offering aos-init mt-10">
                <h2>
                  <span> Our experts </span> are providing and planning 24/7
                  technical support services with product and security
                  consultation.
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-6">
                <div class="single-solution overly-one">
                  <div class="overly-two">
                    <h3>Product Consultation</h3>
                    <p>
                      They educate customers about product features and
                      benefits, answer questions, and assist with orders. They
                      may also demonstrate product operation
                    </p>
                    <span>01</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-6">
                <div class="single-solution overly-one">
                  <div class="overly-two">
                    <h3>Security Consultation</h3>
                    <p>
                      security consulting services include current and emerging
                      threat assessments, policy review and development, and
                      master planning.
                    </p>
                    <span>02</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-solution overly-one">
                  <div class="overly-two">
                    <h3>24/7 Technical Support</h3>
                    <p>
                      24x7 TechSupport has resolved thousands of customer’s
                      Computer/Network problems with high success rate and
                      customer satisfaction Levels
                    </p>
                    <span>03</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="solution-img-two text-center">
            <img
              src="assets/img/Data-security-2.png"
              alt="Image"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="solution-shape-left shape-three">
      <img src="assets/img/solution-shape-left.png" alt="Image" />
    </div>
  </div>
</section>
