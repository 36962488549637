<!-- <section class="about-header-section pt-120 mob-pt-120 position-relative overflow-hidden banner-height">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-heading-wrap d-flex justify-content-between align-items-center z-5 position-relative">
                    <div class="about-content-left">
                        <div class="about-info" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                            <h1 class="fw-bold display-5 text-white text-shadow">Mobile Development</h1>
                            <p class="lead text-light text-shadow">Mobile application development services provided by ASZ Technologies are incorporated with advanced technologies, for developing secure and reliable solutions for diverse platforms, including iPhone/iPad, Blackberry, Android and Windows.</p>
                                <p class="lead text-light"> Employing the best of latest mobile technology, which are sure to enhance the user’s experience.</p>
                            
                        </div>
                       
                    </div>
                    <div class="about-content-right">
                        <img src="assets/img/asz/mob_banner_img.png" alt="about" class="img-fluid rounded-custom" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section> -->

<section class="heading_banner">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="banner_content">
                    <h1>Mobile Development</h1>
                    <p>
                        Mobile application development services provided by ASZ Technologies are incorporated with advanced technologies, for developing secure and reliable solutions for diverse platforms.      </p>

                    <li><a><span><img src="assets/img/asz/appstore-tra-white.png"></span></a></li>
                    <li><a><span><img src="assets/img/asz/googleplay-tra-white.png"></span></a></li>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mobile_img">
                    <img src="assets/img/asz/hero-5-img.png">
                </div>
            </div>
        </div>
    </div>
  </section>

  <div class="about_our">
    <div class="container">
        <div class="heading">
            <!-- <h2><span>About</span> Our Works</h2> -->
            <h2><span>Experts</span> of mobile-first releases</h2>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-4 ex-50">
                <div class="style-card1-wrap">
                    <div class="style-card1">
                        <div class="style-card1-inner">
                            <img src="../../../../assets/img/asz/mobile-app2.png" class="img-fluid">
                            <div class="style-card1-content">
                                <h3 class="style-card1-heading-2">Custom<br>app development</h3>
                                <p class="style-card1-paragraph-2">We built purpose-relevant mobile-first applications that serve your business bottomline. We attend the project’s requirements with detailed inspection and align it with the strict development protocols to conceive and create the right fit of solution for your business.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 ex-50">
                <div class="style-card1-wrap">
                    <div class="style-card1">
                        <div class="style-card1-inner">
                            <img src="../../../../assets/img/asz/mobile-app2.png" class="img-fluid">
                            <div class="style-card1-content">
                                <h3 class="style-card1-heading-2">Mobile app <br>  UI and UX design</h3>
                                <p class="style-card1-paragraph-2">We create highly intuitive and responsive interfaces for mobile application development solutions. These program interfaces ensure maximum conversions, improved engagement, and easy adoption. Get interactive UI and UX design for your mobile app with us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 ex-50">
                <div class="style-card1-wrap">
                    <div class="style-card1">
                        <div class="style-card1-inner">
                            <img src="../../../../assets/img/asz/mobile-app2.png" class="img-fluid">
                            <div class="style-card1-content">
                                <h3 class="style-card1-heading-2">Full-cycle mobile<br>app development</h3>
                                <p class="style-card1-paragraph-2">We offer a comprehensive range of mobile app development solutions that are tailor-made to meet the unique requirements of our clients. We help in designing great user experiences while integrating novel and advanced features including voice and chat capabilities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 ex-50">
                <div class="style-card1-wrap">
                    <div class="style-card1">
                        <div class="style-card1-inner">
                            <img src="../../../../assets/img/asz/mobile-app2.png" class="img-fluid">
                            <div class="style-card1-content">
                                <h3 class="style-card1-heading-2">Mobile<br>app consulting</h3>
                                <p class="style-card1-paragraph-2">Whether you are designing a new mobile app or improving the existing one, we can help you in shaping the project strategy right from scratch. Our mobile app development consulting services also help with serving your business objectives, marketing goals, and technical capacity.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 ex-50">
                <div class="style-card1-wrap">
                    <div class="style-card1">
                        <div class="style-card1-inner">
                            <img src="../../../../assets/img/asz/mobile-app2.png" class="img-fluid">
                            <div class="style-card1-content">
                                <h3 class="style-card1-heading-2">Hybrid<br>app development</h3>
                                <p class="style-card1-paragraph-2">Our team of hybrid app developers features qualified UI/UX designers, software engineers, and program architects having deep expertise in leading mobile technologies. Our hybrid mobile app development solutions are top-performing, robust, and feature-packed to handle all business needs on the go.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 ex-50">
                <div class="style-card1-wrap">
                    <div class="style-card1">
                        <div class="style-card1-inner">
                            <img src="../../../../assets/img/asz/mobile-app2.png" class="img-fluid">
                            <div class="style-card1-content">
                                <h3 class="style-card1-heading-2">Wearables and<br>smart apps</h3>
                                <p class="style-card1-paragraph-2">Our vast exposure across cross-technologies such as Internet of Things and AI/ML, makes us a right choice to be your partner in designing apps for wearables and smart devices. We develop companion apps for a number of wearable devices, integrate with smart devices or proprietary hardware.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        <!-- <div class="application_img">
            <img src="assets/img/asz/application_product.png" alt="img">
        </div> -->

        <!-- <carousel
        [cellsToShow]="3"
        [cellsToScroll]="1"
        [arrows]="true"
        [dots]="true"
      >
        <div class="carousel-cell bx_wrapper">
            <img src="../../../../assets/img/img-w1.jpg"  class="img-fluid">
        </div>
        <div class="carousel-cell bx_wrapper">
            <img src="../../../../assets/img/img-w2.jpg"  class="img-fluid">
        </div>
        <div class="carousel-cell bx_wrapper">
            <img src="../../../../assets/img/img-w3.jpg"  class="img-fluid">
        </div>
        <div class="carousel-cell bx_wrapper">
            <img src="../../../../assets/img/img-w1.jpg"  class="img-fluid">
        </div>
        <div class="carousel-cell bx_wrapper">
            <img src="../../../../assets/img/img-w3.jpg"  class="img-fluid">
        </div>
        <div class="carousel-cell bx_wrapper">
            <img src="../../../../assets/img/img-w2.jpg"  class="img-fluid">
        </div>
      </carousel> -->
        
    </div>
  </div>


  <section class="customizable">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="customizable_img">
                    <img src="assets/img/asz/mobile-app-bg2.png" class="img-fluid">
                </div>
            </div>
            <div class="col-md-6">
<div class="customizable_content">
    <div class="heading">
        <h2><span>Fully</span> Customizable</h2>
    </div>
    <ul>
        <li>Android Application</li>
        <li>iphone/ipad Application</li>
        <li>Windows Phone Application</li>
        <li>HTML5 Based Applications</li>
    </ul>
    <p>However big or small your need, we’ll build you a beautiful mobile app that’s tailored specifically to your business needs.</p>
    <p>Our team of highly capable developers have a breadth of experience developing apps for many industries and can build any type of functionality for your app.</p>
    <p>Design is not just what it looks like and feels like. Design is how it works. We pride ourselves in designing apps that are both visually stunning and highly intuitive.</p>
</div>
            </div>
        </div>
    </div>
  </section>

  <section class="stunning">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="stunning_content">
                    <h3>Stunning landing page for your App</h3>
                    <li><a><span><img src="assets/img/asz/appstore-tra-white.png"></span></a></li>
                    <li><a><span><img src="assets/img/asz/googleplay-tra-white.png"></span></a></li>

                    <p>Applications that are custom-built provide a highly interactive UI to the user and can be easily downloaded from the app stores. Every custom application is built with a definite set of functions and activities to perform. </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="download-2-img">
                    <img src="assets/img/asz/image-10.png">
                </div>
            </div>
        </div>
    </div>
  </section>

  

<!-- <section class="masonary-blog-section ptb-120" style="background: url('assets/img/section-bg/section-bg17.webp')no-repeat; background-size: cover; background-position: center top; ">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <div class="single-article feature-article rounded-custom my-3">
                    <a href="" class="article-img">
                        <img src="assets/img/img-mob1.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <div class="article-category mb-4 d-block">
                            <a href="javascript:;" class="d-inline-block text-dark badge bg-primary-soft font-2em"><i class="fa fa-android" aria-hidden="true"></i></a>
                        </div>
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">Android App Development</h2>
                        </a>
                        <p class="limit-2-line-text">Target the extensive Android app market with robust, feature-rich, UI/UX-friendly mobile apps. Accelerate your business growth with successful apps by relying on our  Android app development services in India  and the expertise of industry-level app developers</p>

                        <a href="">
                            <div class="d-flex align-items-center pt-4">
                                <div class="avatar-info">
                                    <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                </div>
                            </div>
                        </a>

                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <div class="single-article feature-article rounded-custom my-3">
                    <a href="" class="article-img">
                        <img src="assets/img/img-mob2.jpg" alt="article" class="img-fluid">
                    </a>
                    <div class="article-content p-4">
                        <div class="article-category mb-4 d-block">
                            <a href="javascript:;" class="d-inline-block text-dark badge bg-danger-soft font-2em"><i class="fa fa-apple" aria-hidden="true"></i></a>
                        </div>
                        <a href="">
                            <h2 class="h5 article-title limit-2-line-text">iOS App Development</h2>
                        </a>
                        <p class="limit-2-line-text">Reach the elite prospects with a scalable, stable, and full-fledged iOS app developed by our skilled & professional team. We have a decade of expertise and a successful line of the portfolio in developing iOS apps as a leading mobile app development company</p>

                        <a href="">
                            <div class="d-flex align-items-center pt-4">
                                <div class="avatar-info">
                                    <h6 class="mb-0 avatar-name">Read More...</h6>                                    
                                </div>
                            </div>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

 



<section>
<div class="container">
    <div class="mobile-process-main">
        <div class="mobile_app_process_title">
            <div class="heading">
                <h2><span>Mobile Application</span> Development Process</h2></div>
            <p>Step by step app development Process to launch your powerful iOS and Android mobile application.</p>
            </div>
        <ul>
             <li>
               
                    <div class="design-process">
                        <img class="img-fluid" src="../../../../assets/img/asz/app-icon2.png">
                    </div>
                    Analysis of requirement 
              </li>
            <li>
                    <div class="design-process">
                        <img class="img-fluid" src="../../../../assets/img/asz/app-icon6.png">
                    </div>
                    Wireframing &amp; Designing
            </li>
            <li>
                    <div class="design-process">
                        <img class="img-fluid" src="../../../../assets/img/asz/app-icon3.png">
                    </div>
                    Development
            </li>
            <li>
                    <div class="design-process">
                        <img class="img-fluid" src="../../../../assets/img/asz/app-icon4.png">
                    </div>
                    Testing
            </li>
            <li>
                    <div class="design-process">
                        <img class="img-fluid" src="../../../../assets/img/asz/app-icon1.png">
                    </div>
                    Deployment & Support
            </li>
        </ul>
    </div>
</div>
</section>