<!--page header section start-->
<section class=" position-relative overflow-hidden bg_dark page-header"
    style="background: url('assets/img/page-header-bg.svg')no-repeat bottom left">
    <div class="container ">
        <div class="row">
            <div class="col-lg-8 col-md-12" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <h1 class="display-5 fw-bold">Accurate Data Analytics Services</h1>
                <p class="lead">ASZ technologies has years of experience 
                    implementing Business Intelligence tools and aligning 
                    them with strategic enterprise goals. ASZ technologies 
                    dedicated analytics teams have empowered clients across 
                    the globe with the insight needed for critical decision 
                    making.</p>
            </div>
        </div>
        <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg_dark-light right-5"></div>
    </div>
</section>
<!--page header section end-->

<!--feature section start-->
<section class="feature-section ptb-120 analytics-bg">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                <div class="mb-4">
                    <h2 data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">Analyze your data with a power of BI</h2>
                    <p data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">ASZ technologies bring BI accelerators to address 
                        challenges common within a market segment. Our solutions 
                        architects will create a blue print tailored to the unique 
                        technical and business requirements of your organization, and 
                        our global delivery teams will ensure the end-to-end 
                        implementation meets the needs of all stakeholders. </p>
                </div>
                <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5">
                    <li data-aos="fade-up-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <span class="flex-center pb-15"><img class="w-64" src="assets/img/bi-person.png"/></span>
                        <h3 class="h5">Customer analysis</h3>
                        <p>Objectively exceptional via customized intellectual.</p>
                    </li>
                    <li data-aos="fade-up-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <span class="flex-center pb-15"><img class="w-64" src="assets/img/shop.png"/></span>
                        <h3 class="h5">Enhanced Business</h3>
                        <p>Interactively integrate extensible directed developer. </p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 order-lg-1">
                <div class="pr-lg-4 mt-md-4 position-relative">
                    <div class="bg-light text-center rounded-custom overflow-hidden mx-lg-auto ">
                        <img src="assets/img/BIA2.jpg" alt="" class="img-fluid shadow-sm rounded-custom" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                        <div
                            class="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic" >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section end-->

<!--feature section start-->
<section class="feature-section pt-60 pb-120 section-dark">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-1 mb-7 mb-lg-0">
                <div class="mb-4">
                    <h2 data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">Make data your competitive advantage</h2>
                    <p data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">ASZ Analytics lets you easily define an unlimited number of 
                        custom metrics and use them anywhere. Want at-a-glance 
                        numbers, trends over time, or a deep dive? Simply choose 
                        your interface. With ASZ, you can always access the 
                        insights you need, in a format you understand. </p>
                </div>
                <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5">
                    <li data-aos="fade-up-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <span class="flex-center pb-15"><img class="w-64" src="assets/img/AI.png"/></span>
                        <h3 class="h5">AI is always on duty</h3>
                        <p>Objectively exceptional via customized intellectual.</p>
                    </li>
                    <li data-aos="fade-up-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        <span class="flex-center pb-15"><img class="w-64" src="assets/img/statistics.png"/></span>
                        <h3 class="h5">Your data, your call</h3>
                        <p>Interactively integrate extensible directed developer. </p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 order-lg-2">
                <div class="pr-lg-4 position-relative">
                    <div class="bg-light text-center rounded-custom overflow-hidden p-lg-5 p-4 mx-lg-auto bg-faint" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                        <img src="assets/img/bia1.jpg" alt="" class="img-fluid rounded-custom shadow-sm">
                        <div
                            class="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section end-->

<!--Services section start-->
<section class="service-section ptb-120 service-bg">
    <div class="container">
        <div class="row justify-content-center" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center analytics-aec">
                    <h2>What Services Do We Provide ?</h2>
                    <p>We offer next level Data Analysis solutions to meet your Requirements as well as to meet our Market standards.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" style="row-gap:30px">
            <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="100">
                <div
                    class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4 h-300">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4"><img src="../../../../assets/img/analysis1.png"></h3>
                        <h2 class="h5">Business Strategy</h2>
                        <p class="mb-0">Statistics and linguistics combined to provide more accurate sentiment analysis results.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="400">
                <div
                    class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4 h-300">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4"><img src="../../../../assets/img/analysis1.png"></h3>
                        <h2 class="h5">Business Process</h2>
                        <p class="mb-0">Context of features examined for accurate interpretations.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="700">
                <div
                    class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-0 h-300">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4"><img src="../../../../assets/img/analysis1.png"></h3>
                        <h2 class="h5">BI and Analytics
                        </h2>
                        <p class="mb-0">Business Intelligence & Advanced Analytics
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                <div
                    class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-0 h-300">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4"><img src="../../../../assets/img/analysis1.png"></h3>
                        <h2 class="h5">Data Solutions</h2>
                        <p class="mb-0">Information Management & Data Warehouse Services

                        </p>
                    </div>
                </div>
            </div>
            
            <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="1300">
                <div
                    class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-0 h-300">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4"><img src="../../../../assets/img/analysis1.png"></h3>
                        <h2 class="h5">R&D</h2>
                        <p class="mb-0">R&D & Other Value Added Services

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Services section end-->

<!--promo section start-->
<section class="promo-section ptb-120 section-dark">
    <div class="container">
        <div class="row justify-content-center" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center">
                    <h2>Everything We Do it With Love</h2>
                    <p>Progressively deploy market positioning catalysts for change and technically sound
                        authoritatively e-enable resource-leveling infrastructures. </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <div
                    class="promo-card-wrap promo-border-hover border border-2 bg-transparent rounded-custom p-5 mb-4 mb-lg-0 mb-md-4">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">150+</h3>
                        <h2 class="h5">Completed Projects</h2>
                        <p class="mb-0">Rapidiously embrace distinctive best practices after B2B syndicate. </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <div
                    class="promo-card-wrap promo-border-hover border border-2 bg-transparent rounded-custom p-5 mb-4 mb-lg-0 mb-md-4">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">1.5k</h3>
                        <h2 class="h5">Happy Client</h2>
                        <p class="mb-0">Energistically impact synergistic convergence for leadership..</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                <div
                    class="promo-card-wrap promo-border-hover border border-2 bg-transparent rounded-custom p-5 mb-4 mb-lg-0 mb-md-0">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">90%</h3>
                        <h2 class="h5">24/7 Support</h2>
                        <p class="mb-0">Synergistically revolutionize leadership whereas platform. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--promo section end-->

<!--register section start-->
<section class="sign-up-in-section bg_dark ptb-120 position-relative overflow-hidden">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0">
                <div class="testimonial-tab-slider-wrap mt-5 mt-lg-0 mt-xl-0">
                    <h2 class="text-white" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">Start Your Project with Us</h2>
                    <p data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">Whatever your goal or project size we will handel it utilize standards compliant. We hope you
                        will be 100% satisfied.</p>
                    <hr>
                    <div class="tab-content testimonial-tab-content text-white-80" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="testimonial-tab-1" role="tabpanel">
                            <blockquote class="blockquote" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                                <em>"Globally actualize economically sound alignments before tactical systems.
                                    Rapidiously actualize processes technically sound infomediaries. Holisticly
                                    pursue team building catalysts for change before team driven products. "</em>
                            </blockquote>
                            <div class="author-info mt-3" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic">
                                <span class="h6">Veronica P. Byrd</span>
                                <span class="d-block small">Veterinary technician</span>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="testimonial-tab-2" role="tabpanel">
                            <blockquote class="blockquote">
                                <em>"Synergistically evisculate market positioning technology vis-a-vis team driven
                                    innovation. Phosfluorescently morph tactical communities for superior
                                    applications. Distinctively pontificate resource-leveling infomediaries and
                                    parallel models. "</em>
                            </blockquote>
                            <div class="author-info mt-3">
                                <span class="h6">Raymond H. Gilbert</span>
                                <span class="d-block small">Forest fire inspector</span>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="testimonial-tab-3" role="tabpanel">
                            <blockquote class="blockquote">
                                <em>"Professionally myocardinate corporate e-commerce through alternative
                                    functionalities. Compellingly matrix distributed convergence with goal-oriented
                                    synergy. Professionally embrace interactive opportunities through parallel
                                    innovation. "</em>
                            </blockquote>
                            <div class="author-info mt-3">
                                <span class="h6">Donna R. Book</span>
                                <span class="d-block small">Extractive engineer</span>
                            </div>
                        </div>
                    </div>
                    <ul class="nav nav-pills mb-0 testimonial-tab-indicator mt-5" id="pills-tab" role="tablist" >
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" data-bs-toggle="pill" data-bs-target="#testimonial-tab-1" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="100"
                                type="button" role="tab" aria-selected="true">
                                <img src="assets/img/testimonial/1.jpg" alt="testimonial" width="55"
                                    class="img-fluid rounded-circle">
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" data-bs-toggle="pill" data-bs-target="#testimonial-tab-2" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200"
                                type="button" role="tab" aria-selected="false">
                                <img src="assets/img/testimonial/4.jpg" alt="testimonial" width="55"
                                    class="img-fluid rounded-circle">
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" data-bs-toggle="pill" data-bs-target="#testimonial-tab-3" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="300"
                                type="button" role="tab" aria-selected="false">
                                <img src="assets/img/testimonial/6.jpg" alt="testimonial" width="55"
                                    class="img-fluid rounded-circle">
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1">
                <div class="register-wrap p-5 bg-white shadow rounded-custom" data-aos="zoom-out" data-aos-duration="2000" data-aos-easing="ease-out-cubic">
                    <h3 class="fw-medium" data-aos="fade-down" data-aos-duration="800" data-aos-easing="ease-out-cubic">Fill out the form and we'll be in touch as soon as possible.</h3>

                    <form action="#" class="mt-4 register-form">
                        <div class="row">
                            <div class="col-sm-6" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Name" aria-label="name">
                                </div>
                            </div>
                            <div class="col-sm-6 " data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200">
                                <div class="input-group mb-3">
                                    <input type="email" class="form-control" placeholder="Email" aria-label="email">
                                </div>
                            </div>
                            <div class="col-sm-6" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="400">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Company website"
                                        aria-label="company-website">
                                </div>
                            </div>
                            <div class="col-sm-6" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="400">
                                <div class="input-group mb-3">
                                    <input type="email" class="form-control" placeholder="Work email"
                                        aria-label="work-Email">
                                </div>
                            </div>
                            <div class="col-sm-6 select-index" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                                <div class="input-group mb-3">
                                    <!-- <select class="form-control form-select" name="budget" id="budget" required=""
                                        data-msg="Please select your budget.">
                                        <option value="" selected="" disabled="">Budget</option>
                                        <option value="budget1">None, just getting started</option>
                                        <option value="budget1">Less than $20,000</option>
                                        <option value="budget1">$20,000 to $50,000</option>
                                        <option value="budget1">$50,000 to $100,000</option>
                                        <option value="budget2">$100,000 to $500,000</option>
                                        <option value="budget3">More than $500,000</option>
                                    </select> -->

                                   
                                    <div ngbDropdown class="select-dropdown">
                                        <button type="button" class="btn" id="dropdownManual" ngbDropdownToggle><p> {{selectedValue}} </p></button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic" class="select-main">
                                            <option ngbDropdownItem value="budget1" (click)="ChangeValue('None, just getting started', 'budget')">None, just getting started</option>
                                            <option ngbDropdownItem value="budget1" (click)="ChangeValue('Less than $20,000', 'budget')">Less than $20,000</option>
                                            <option ngbDropdownItem value="budget1" (click)="ChangeValue('$20,000 to $50,000', 'budget')">$20,000 to $50,000</option>
                                            <!-- <option value="budget1">$50,000 to $100,000</option>
                                            <option value="budget2">$100,000 to $500,000</option>
                                            <option value="budget3">More than $500,000</option> -->
                                        </div>
                                      </div>
                                </div>
                            </div>
                            <div class="col-sm-6 select-index" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                                <div class="input-group mb-3">
                                    <!-- <select class="form-control form-select" name="country" id="country" required=""
                                        data-msg="Please select your country.">
                                        <option value="" selected="" disabled="">Country</option>
                                        <option value="AF">Afghanistan</option>
                                        <option value="AX">Åland Islands</option>
                                        <option value="AL">Albania</option>
                                        <option value="DZ">Algeria</option>
                                        <option value="AS">American Samoa</option>
                                        <option value="AD">Andorra</option>
                                        <option value="AO">Angola</option>
                                        <option value="AI">Anguilla</option>
                                        <option value="AQ">Antarctica</option>
                                        <option value="AG">Antigua and Barbuda</option>
                                        <option value="AR">Argentina</option>
                                        <option value="AM">Armenia</option>
                                        <option value="AW">Aruba</option>
                                        <option value="AU">Australia</option>
                                        <option value="AT">Austria</option>
                                        <option value="AZ">Azerbaijan</option>
                                        <option value="BS">Bahamas</option>
                                        <option value="BH">Bahrain</option>
                                        <option value="BD">Bangladesh</option>
                                        <option value="BB">Barbados</option>
                                        <option value="BY">Belarus</option>
                                        <option value="BE">Belgium</option>
                                        <option value="BZ">Belize</option>
                                        <option value="BJ">Benin</option>
                                        <option value="BM">Bermuda</option>
                                        <option value="BT">Bhutan</option>
                                        <option value="BO">Bolivia, Plurinational State of</option>
                                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                        <option value="BA">Bosnia and Herzegovina</option>
                                        <option value="BW">Botswana</option>
                                        <option value="BV">Bouvet Island</option>
                                        <option value="BR">Brazil</option>
                                        <option value="IO">British Indian Ocean Territory</option>
                                        <option value="BN">Brunei Darussalam</option>
                                        <option value="BG">Bulgaria</option>
                                        <option value="BF">Burkina Faso</option>
                                        <option value="BI">Burundi</option>
                                        <option value="KH">Cambodia</option>
                                        <option value="CM">Cameroon</option>
                                        <option value="CA">Canada</option>
                                        <option value="CV">Cape Verde</option>
                                        <option value="KY">Cayman Islands</option>
                                        <option value="CF">Central African Republic</option>
                                        <option value="TD">Chad</option>
                                        <option value="CL">Chile</option>
                                        <option value="CN">China</option>
                                        <option value="CX">Christmas Island</option>
                                        <option value="CC">Cocos (Keeling) Islands</option>
                                        <option value="CO">Colombia</option>
                                        <option value="KM">Comoros</option>
                                        <option value="CG">Congo</option>
                                        <option value="CD">Congo, the Democratic Republic of the</option>
                                        <option value="CK">Cook Islands</option>
                                        <option value="CR">Costa Rica</option>
                                        <option value="CI">Côte d'Ivoire</option>
                                        <option value="HR">Croatia</option>
                                        <option value="CU">Cuba</option>
                                        <option value="CW">Curaçao</option>
                                        <option value="CY">Cyprus</option>
                                        <option value="CZ">Czech Republic</option>
                                        <option value="DK">Denmark</option>
                                        <option value="DJ">Djibouti</option>
                                        <option value="DM">Dominica</option>
                                        <option value="DO">Dominican Republic</option>
                                        <option value="EC">Ecuador</option>
                                        <option value="EG">Egypt</option>
                                        <option value="SV">El Salvador</option>
                                        <option value="GQ">Equatorial Guinea</option>
                                        <option value="ER">Eritrea</option>
                                        <option value="EE">Estonia</option>
                                        <option value="ET">Ethiopia</option>
                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                        <option value="FO">Faroe Islands</option>
                                        <option value="FJ">Fiji</option>
                                        <option value="FI">Finland</option>
                                        <option value="FR">France</option>
                                        <option value="GF">French Guiana</option>
                                        <option value="PF">French Polynesia</option>
                                        <option value="TF">French Southern Territories</option>
                                        <option value="GA">Gabon</option>
                                        <option value="GM">Gambia</option>
                                        <option value="GE">Georgia</option>
                                        <option value="DE">Germany</option>
                                        <option value="GH">Ghana</option>
                                        <option value="GI">Gibraltar</option>
                                        <option value="GR">Greece</option>
                                        <option value="GL">Greenland</option>
                                        <option value="GD">Grenada</option>
                                        <option value="GP">Guadeloupe</option>
                                        <option value="GU">Guam</option>
                                        <option value="GT">Guatemala</option>
                                        <option value="GG">Guernsey</option>
                                        <option value="GN">Guinea</option>
                                        <option value="GW">Guinea-Bissau</option>
                                        <option value="GY">Guyana</option>
                                        <option value="HT">Haiti</option>
                                        <option value="HM">Heard Island and McDonald Islands</option>
                                        <option value="VA">Holy See (Vatican City State)</option>
                                        <option value="HN">Honduras</option>
                                        <option value="HK">Hong Kong</option>
                                        <option value="HU">Hungary</option>
                                        <option value="IS">Iceland</option>
                                        <option value="IN">India</option>
                                        <option value="ID">Indonesia</option>
                                        <option value="IR">Iran, Islamic Republic of</option>
                                        <option value="IQ">Iraq</option>
                                        <option value="IE">Ireland</option>
                                        <option value="IM">Isle of Man</option>
                                        <option value="IL">Israel</option>
                                        <option value="IT">Italy</option>
                                        <option value="JM">Jamaica</option>
                                        <option value="JP">Japan</option>
                                        <option value="JE">Jersey</option>
                                        <option value="JO">Jordan</option>
                                        <option value="KZ">Kazakhstan</option>
                                        <option value="KE">Kenya</option>
                                        <option value="KI">Kiribati</option>
                                        <option value="KP">Korea, Democratic People's Republic of</option>
                                        <option value="KR">Korea, Republic of</option>
                                        <option value="KW">Kuwait</option>
                                        <option value="KG">Kyrgyzstan</option>
                                        <option value="LA">Lao People's Democratic Republic</option>
                                        <option value="LV">Latvia</option>
                                        <option value="LB">Lebanon</option>
                                        <option value="LS">Lesotho</option>
                                        <option value="LR">Liberia</option>
                                        <option value="LY">Libya</option>
                                        <option value="LI">Liechtenstein</option>
                                        <option value="LT">Lithuania</option>
                                        <option value="LU">Luxembourg</option>
                                        <option value="MO">Macao</option>
                                        <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                        <option value="MG">Madagascar</option>
                                        <option value="MW">Malawi</option>
                                        <option value="MY">Malaysia</option>
                                        <option value="MV">Maldives</option>
                                        <option value="ML">Mali</option>
                                        <option value="MT">Malta</option>
                                        <option value="MH">Marshall Islands</option>
                                        <option value="MQ">Martinique</option>
                                        <option value="MR">Mauritania</option>
                                        <option value="MU">Mauritius</option>
                                        <option value="YT">Mayotte</option>
                                        <option value="MX">Mexico</option>
                                        <option value="FM">Micronesia, Federated States of</option>
                                        <option value="MD">Moldova, Republic of</option>
                                        <option value="MC">Monaco</option>
                                        <option value="MN">Mongolia</option>
                                        <option value="ME">Montenegro</option>
                                        <option value="MS">Montserrat</option>
                                        <option value="MA">Morocco</option>
                                        <option value="MZ">Mozambique</option>
                                        <option value="MM">Myanmar</option>
                                        <option value="NA">Namibia</option>
                                        <option value="NR">Nauru</option>
                                        <option value="NP">Nepal</option>
                                        <option value="NL">Netherlands</option>
                                        <option value="NC">New Caledonia</option>
                                        <option value="NZ">New Zealand</option>
                                        <option value="NI">Nicaragua</option>
                                        <option value="NE">Niger</option>
                                        <option value="NG">Nigeria</option>
                                        <option value="NU">Niue</option>
                                        <option value="NF">Norfolk Island</option>
                                        <option value="MP">Northern Mariana Islands</option>
                                        <option value="NO">Norway</option>
                                        <option value="OM">Oman</option>
                                        <option value="PK">Pakistan</option>
                                        <option value="PW">Palau</option>
                                        <option value="PS">Palestinian Territory, Occupied</option>
                                        <option value="PA">Panama</option>
                                        <option value="PG">Papua New Guinea</option>
                                        <option value="PY">Paraguay</option>
                                        <option value="PE">Peru</option>
                                        <option value="PH">Philippines</option>
                                        <option value="PN">Pitcairn</option>
                                        <option value="PL">Poland</option>
                                        <option value="PT">Portugal</option>
                                        <option value="PR">Puerto Rico</option>
                                        <option value="QA">Qatar</option>
                                        <option value="RE">Réunion</option>
                                        <option value="RO">Romania</option>
                                        <option value="RU">Russian Federation</option>
                                        <option value="RW">Rwanda</option>
                                        <option value="BL">Saint Barthélemy</option>
                                        <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                        <option value="KN">Saint Kitts and Nevis</option>
                                        <option value="LC">Saint Lucia</option>
                                        <option value="MF">Saint Martin (French part)</option>
                                        <option value="PM">Saint Pierre and Miquelon</option>
                                        <option value="VC">Saint Vincent and the Grenadines</option>
                                        <option value="WS">Samoa</option>
                                        <option value="SM">San Marino</option>
                                        <option value="ST">Sao Tome and Principe</option>
                                        <option value="SA">Saudi Arabia</option>
                                        <option value="SN">Senegal</option>
                                        <option value="RS">Serbia</option>
                                        <option value="SC">Seychelles</option>
                                        <option value="SL">Sierra Leone</option>
                                        <option value="SG">Singapore</option>
                                        <option value="SX">Sint Maarten (Dutch part)</option>
                                        <option value="SK">Slovakia</option>
                                        <option value="SI">Slovenia</option>
                                        <option value="SB">Solomon Islands</option>
                                        <option value="SO">Somalia</option>
                                        <option value="ZA">South Africa</option>
                                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                                        <option value="SS">South Sudan</option>
                                        <option value="ES">Spain</option>
                                        <option value="LK">Sri Lanka</option>
                                        <option value="SD">Sudan</option>
                                        <option value="SR">Suriname</option>
                                        <option value="SJ">Svalbard and Jan Mayen</option>
                                        <option value="SZ">Swaziland</option>
                                        <option value="SE">Sweden</option>
                                        <option value="CH">Switzerland</option>
                                        <option value="SY">Syrian Arab Republic</option>
                                        <option value="TW">Taiwan, Province of China</option>
                                        <option value="TJ">Tajikistan</option>
                                        <option value="TZ">Tanzania, United Republic of</option>
                                        <option value="TH">Thailand</option>
                                        <option value="TL">Timor-Leste</option>
                                        <option value="TG">Togo</option>
                                        <option value="TK">Tokelau</option>
                                        <option value="TO">Tonga</option>
                                        <option value="TT">Trinidad and Tobago</option>
                                        <option value="TN">Tunisia</option>
                                        <option value="TR">Turkey</option>
                                        <option value="TM">Turkmenistan</option>
                                        <option value="TC">Turks and Caicos Islands</option>
                                        <option value="TV">Tuvalu</option>
                                        <option value="UG">Uganda</option>
                                        <option value="UA">Ukraine</option>
                                        <option value="AE">United Arab Emirates</option>
                                        <option value="GB">United Kingdom</option>
                                        <option value="US">United States</option>
                                        <option value="UM">United States Minor Outlying Islands</option>
                                        <option value="UY">Uruguay</option>
                                        <option value="UZ">Uzbekistan</option>
                                        <option value="VU">Vanuatu</option>
                                        <option value="VE">Venezuela, Bolivarian Republic of</option>
                                        <option value="VN">Viet Nam</option>
                                        <option value="VG">Virgin Islands, British</option>
                                        <option value="VI">Virgin Islands, U.S.</option>
                                        <option value="WF">Wallis and Futuna</option>
                                        <option value="EH">Western Sahara</option>
                                        <option value="YE">Yemen</option>
                                        <option value="ZM">Zambia</option>
                                        <option value="ZW">Zimbabwe</option>
                                    </select> -->
                               
                                    <div ngbDropdown class="select-dropdown">
                                        <button type="button" class="btn" id="dropdownManual" ngbDropdownToggle><p>{{slectedCountry}}</p></button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <option ngbDropdownItem value="AF" (click)="ChangeValue('Afghanistan', 'country')">Afghanistan</option>
                                            <option ngbDropdownItem value="AX" (click)="ChangeValue('Åland Islands', 'country')">Åland Islands</option>
                                            <option ngbDropdownItem value="AL" (click)="ChangeValue('Albania', 'country')">Albania</option>
                                            <!-- <option value="DZ">Algeria</option>
                                            <option value="AS">American Samoa</option>
                                            <option value="AD">Andorra</option>
                                            <option value="AO">Angola</option>
                                            <option value="AI">Anguilla</option>
                                            <option value="AQ">Antarctica</option> -->
                                        </div>
                                      </div>
                                </div>
                            </div>
                            <div class="col-12" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="800">
                                <div class="input-group mb-3">
                                    <textarea class="form-control"
                                        placeholder="Tell us more about your project, needs and budget"
                                        style="height: 120px"></textarea>
                                </div>
                            </div>
                            <div class="col-12"  data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="500">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <label class="form-check-label small" for="flexCheckChecked">
                                        Yes, I'd like to receive occasional marketing emails from us. I have the
                                        right to opt out at any time.
                                        <a href="#"> View privacy policy</a>.
                                    </label>
                                </div>
                            </div>
                            <div class="col-12" data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="1200">
                                <button type="submit" class="btn btn-primary mt-4 d-block w-100">Get Started
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg_dark-light left-5"></div>
        <!-- <div class="bg-circle rounded-circle circle-shape-1 position-absolute bg-warning right-5"></div> -->
    </div>
</section>
<!--register section end-->