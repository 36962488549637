<section class="about-header-section pt-120 position-relative overflow-hidden">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-heading-wrap d-flex justify-content-between z-5 position-relative">
                    <div class="about-content-left" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200">
                        <div class="about-info mb-5">
                            <h1 class="fw-bold display-5">Grow your Business & Customer Satisfaction with
                                ASZ</h1>
                            <p class="lead">Dynamically disintermediate technically sound technologies with
                                compelling quality vectors error-free communities. </p>
                            <a routerLink="/career" class="btn btn-primary mt-4 me-3">Open Positions</a>
                            <!-- <a href="Javascript:void(0)" class="btn btn-soft-primary mt-4">Meet Our Team</a> -->
                        </div>
                        <!-- <img src="assets/img/company-img1.png" alt="about" class="img-fluid about-img-first mt-5 rounded-custom shadow"> -->
                    </div>
                    <div class="about-content-right">
                        <img src="assets/img/asz/career-jobs.png" alt="about" class="img-fluid mb-5 rounded-custom" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-cubic" data-aos-delay="200">
                        <!-- <img src="assets/img/about-img-3.jpg" alt="about" class="rounded-custom about-img-last shadow"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="bg-white position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z-2 py-5">
    </div> -->
</section>


<section class="description">
    <div class="container">
       <h1>Job Description</h1>
       <div class="business-analyst-icon">
           <div class="bracket">
            <span><img src="assets/img/asz/business-analyst-icon2.png" class="decription_img"></span>
           </div>

           <div class="head">
            <h3>Magento Developer</h3>
          <h5>Experience:2 Years Minimum</h5>
           </div>  
       </div>

       <div class="row">
           <div class="col-md-12">
               <div class="roles">
                   <h4>
                    Role & Responsibility
                   </h4>

                   <ul class="services">
                       <li>Advanced knowledge of Magento 1.9.x.</li>
                       <li>Experience of Magento 2.x.</li>
                       <li>Ability to develop custom extensions.</li>
                       <li>Ability to do customizations.</li>
                       <li>Strong understanding of Magento back-end.</li>
                       <li>Strong in responsive and adaptive web development procedures.</li>
                       <li>Good understanding of front-end technologies, including HTML5, CSS3, JavaScript, jQuery.</li>
                       <li>Knowledge of how to interact with RESTful APIs and formats (JSON, XML).</li>
                       <li>Knowledge of Bitbucket, specifically Git.</li>
                   </ul>

                  
               </div>

               <div class="apply-now-btn">
                <button class="go">Go Back</button>
                <button class="appnow" (click)="careerForm()">Apply Now</button>
            </div>
           </div>
       </div>
    </div>
</section>
