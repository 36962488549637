<section
  class="about-header-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="contact_banner">
          <span>Data Analytics</span>
          <h1>Accurate Data Analytics Services</h1>
          <p>ASZ technologies has years of experience implementing Business Intelligence tools and aligning them with strategic enterprise goals. ASZ technologies dedicated analytics teams have empowered clients across the globe with the insight needed for critical decision making.</p>
          <img src="../../../assets/img/asz/data1.jpg" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>






<!--feature section start-->
<section class="feature-section ptb-80">

    <div class="container">
        <div class="row">

            <div class="col-md-6 col-lg-4 mb-5">
                <div class="content">
                <p class="icon">
                    <i class="fa fa-pie-chart" aria-hidden="true"></i>
                </p>
                <div class="content-text">
                    <h3>SAS Based Application</h3>
                    <p>We design professional looking yet simple websites. Our designs are search engine and user
                        friendly.</p>
                </div>
            </div>
            </div>


            <div class="col-md-6 col-lg-4 mb-5">
                <div class="content">
                <p class="icon">
                    <i class="fa fa-line-chart" aria-hidden="true"></i>
                </p>
                <div class="content-text">
                    <h3>Big Data Analysis</h3>
                    <p>From simple Content Management System to complex eCommerce developer, <br/>we cover it all.</p>
                </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 mb-5">
                <div class="content">
                <p class="icon">
                    <i class="fa fa-cloud" aria-hidden="true"></i>
                </p>
                <div class="content-text">
                    <h3>Systematic Decision</h3>
                    <p>Our comprehensive Online Marketing strategy will boost your website and traffic hence monthly sales.</p>
                </div>
                </div>
            </div>


            <div class="col-md-6 col-lg-4 mb-5">
                <div class="content">
                <p class="icon">
                    <i class="fa fa-shield" aria-hidden="true"></i>
                </p>
                <div class="content-text">
                    <h3>Security</h3>
                    <p>Big Data analytics tools should offer security features to ensure security and safety. One such feature is single sign-on.</p>
                </div>
                </div>
            </div>



            <div class="col-md-6 col-lg-4 mb-5">
                <div class="content">
                <p class="icon">
                    <i class="fa fa-cogs" aria-hidden="true"></i>
                </p>
                <div class="content-text">
                    <h3>Data Processing</h3>
                    <p>Data processing features involve the collection and organization of raw data to produce meaning.</p>
                </div>
                </div>
            </div>



            <div class="col-md-6 col-lg-4 mb-5">
                <div class="content">
                <p class="icon">
                    <i class="fa fa-file-text" aria-hidden="true"></i>
                </p>
                <div class="content-text">
                    <h3>Reporting</h3>
                    <p>Reporting functions keep users on top of their business. Real-time reporting gathers minute-by-minute data and relays it to you.</p>
                </div>
                </div>
            </div>



        </div>
    </div>
</section>
<!--feature section end-->



<section class="feature-section pb-80 box-img">

    <div class="container">
        <div class="row">

            <header>
                <section class="hero">
                    <div class="left">
                        <h1>Make data your<br>competitive<br>advantage <br><span>education</span></h1>
                        <p>ASZ Analytics lets you easily define an unlimited number of custom metrics and use them anywhere. Want at-a-glance numbers, trends over time, or a deep dive? Simply choose your interface.<br><br>
                            With ASZ, you can always access the insights you need, in a format you understand.</p>
                       
                    </div>
                    <div class="right">
                        <img src="../../../assets/img/asz/data.jpg" alt="">
                    </div>
                </section>
            </header>

        </div>
    </div>
</section>




<section class="home_why_choose">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xl-8">
          <div class="about_img">
            <img src="../../../assets/img/asz/data2.jpg" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-6 col-xl-4">
          <div class="about_content">
            <div class="heading">
              <h2><span>Typical</span> Benefits</h2>
              <div class="with-bg-ul">
              <ul>
                <li>Better Query on Data and Understanding of Data</li>
                <li>Efficient Loading on Queries, Reduce Operational Cost</li>
                <li>Live Data Insights for Customers</li>
                <li>Smarter Insights Into Data</li>
                <li>Better Domain Knowledge with Domain Experts</li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


